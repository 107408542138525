// Slide here
.lesson-slide.activity-headlines {
  // background-color: $cream-seashell;
  // color: $grey-space-cadet;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-family: AesteticoInformal;
  }

  .countdown-clock {
    color: white;
  }

  .headlines {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-flow: wrap-reverse;
  }
  .headline {
    max-width: calc(50% - 2vw);
    margin: 0 1vw 4vh;
    position: relative;
    &:nth-child(1) {
      margin-top: -6vh;
      z-index: 3;
    }
    &:nth-child(2) {
      margin-left: -7vw;
      margin-top: 5vh;
      z-index: 2;
    }
    &:nth-child(3) {
      margin-left: -5vw;
      z-index: 4;
    }

    img {
      box-shadow: $box-shadow;
    }
  }
  .student-input .condensed {
    margin: 0 0 5px;
  }

  &.results {
    justify-content: center;
    .answers {
      margin-top: 5vh;
      columns: 3 300px;
      column-gap: 20px;
      min-height: 30vh;
      .answer {
        @extend h5;
        display: inline-block;
        margin: 10px 10px;
        text-align: left;

        span {
          background-color: $grey-feedbacker;
          color: $grey-space-cadet;
          padding: 0;
          border-radius: 10px 0 10px 0;
          box-shadow: 1px 0 0 $grey-feedbacker, 2px 0 0 $grey-feedbacker, 3px 0 0 $grey-feedbacker,
            4px 0 0 $grey-feedbacker, 10px 0 0 $grey-feedbacker, -1px 0 0 $grey-feedbacker,
            2px 0 0 $grey-feedbacker, -3px 0 0 $grey-feedbacker, -4px 0 0 $grey-feedbacker,
            -10px 0 0 $grey-feedbacker;
          margin-right: 20px;
        }
      }
    }
  }
}
