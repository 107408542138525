// Team Badge
.team-badge {
  width: 30vh;
  height: 30vh;
  border-radius: 50%;
  border: 9px solid $champagne-gold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 20vh;
  color: $champagne-gold;
  margin: 3vh;
  font-weight: 900;

  &.ready {
    color: $green-say-yes;
    border-color: transparent;
    background-color: rgba($champagne-gold, 20%);
  }
}

.group-progess,
.group-result {
  .team-badge {
    width: 15vh;
    height: 15vh;
    font-size: 10vh;
    margin-bottom: 1vh;
    border-width: 5px;

    &.complete-100 {
      color: $green-say-yes;
      border-color: $green-say-yes;
    }
  }
}
