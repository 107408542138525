// Slide here
.lesson-slide.question-answer-slide {
  background-color: $cream-seashell;
  color: $grey-space-cadet;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 1.5s ease 1s 1 forwards;
    background: url(/images/slides/shapes/hanging-top-right.svg) no-repeat right / contain;
    pointer-events: none;
  }
  .align-center {
    position: relative;
    z-index: 2;
  }

  h1 {
    color: $blue-all-in;
    max-width: 70%;
    margin-bottom: 60px;
    font-family: AesteticoInformal;
  }

  p {
    @extend .h4;
    color: $grey-independence;
    padding-right: 20px;
    line-height: revert;
  }
}
