// Slide here
.lesson-slide.learning-intention-slide {
  h2, .h2,
  h3, .h3 {
    color: $grey-space-cadet;
  }
  .copy {
    font-size: 1.6vw;
    color: $grey-independence;
    max-width: 60%;
    margin: 20px 0 40px;
  }
}
