// Sections can be added to any page in any order
section.one-col-center {
  padding: 30px 0;

  @include tablet-portrait-up {
    padding: 80px 0;
  }

  h3,
  .h3 {
    font-weight: 900;
    margin-top: 24px;
  }
  p {
    margin-top: 20px;
    font-size: 22px;
    @extend .type-bl;
  }
  .row {
    justify-content: center;
  }
  .copy {
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
    @include tablet-portrait-up {
      width: 60%;
    }
  }
  &.first {
    padding-top: 150px;
  }
  &.last {
    padding-bottom: 150px;
  }
}

section .container.tight-bottom-padding {
  padding-bottom: 0;

  @include tablet-portrait-up {
    padding-bottom: 0;
  }
}
