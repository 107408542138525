// Slide here
.lesson-slide.join-student {
  background: $cream-seashell;
  color: $grey-space-cadet;

  .avatar-creator {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
    .option {
      padding: 10px;
      width: 20%;
    }
    img {
      border-radius: 50%;
      width: 200px;
      border: 5px solid transparent;
      transition: $transition;
      cursor: pointer;
      &.selected {
        border-color: $blue-all-in;
      }
    }
  }

  .avatar-window {
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: column;

    .avatar-preview {
      width: 70%;
      margin-top: 0;
      height: 70vh;
      max-height: 500px;

      img {
        left: 0;
        top: 0;
        max-height: 500px;
      }
    }
  }

  .avatar-options {
    fieldset {
      margin-top: 4px;
      padding: 5px 10px;
      border: 1px solid darken($cream-seashell, 10%);
      border-radius: 10px;

      @include vert-800-up {
        margin-top: 5px;
        padding: 10px;
      }
    }

    .swatchset {
      display: flex;
      justify-content: center;
    }
    .swatch {
      width: 20px;
      height: 20px;
      border-radius: 15px;
      background: white;
      margin: 5px;
      padding: 0;
      cursor: pointer;

      @include vert-800-up {
        width: 30px;
        height: 30px;
      }

      &:focus {
        box-shadow: 0 0 0 2px $cream-seashell, 0 0 0 5px $blue-all-in;
      }

      &.selected {
        box-shadow: 0 0 0 2px $cream-seashell, 0 0 0 5px $green-say-yes;
      }

      &.skin-1 {
        background: #e5579e;
      }
      &.skin-2 {
        background: #823484;
      }
      &.skin-3 {
        background: #b368a8;
      }
      &.skin-4 {
        background: #c993c1;
      }
      &.skin-5 {
        background: #d4a9cd;
      }
      &.skin-6 {
        background: #e24d69;
      }
      &.skin-7 {
        background: #13b3b1;
      }
      &.skin-8 {
        background: #e8acea;
      }

      &.swatch-blue {
        background: #5b8df4;
      }
      &.swatch-green {
        background: #35e1c0;
      }
      &.swatch-pink {
        background: #ffbdff;
      }
      &.swatch-red {
        background: #ff7580;
      }
      &.swatch-yellow {
        background: #ffe074;
      }
      &.swatch-white {
        background: #fff;
      }
      &.swatch-black {
        background: #333344;
      }
      &.swatch-purple {
        background: #a8529b;
      }
    }
    label {
      font-size: 18px;
      margin-bottom: 5px;
    }
    .range-slider {
      margin: 5px 0 20px;
    }
  }
}
