.progress-bars {
  width: 100%;
  border: 1px solid $grey-loveheart;
  border-radius: 3px;
  padding: 10px;

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  h4, .h4 {
    margin-top: 1em;
  }
  .bar {
    padding: 10px;
    border-radius: 4px;
    background: $green-say-yes;
    color: white;
    font-weight: 700;

    &.red {
      background: $coral-red;
    }
  }
}
