// Forms

form,
.form {
  fieldset {
    margin-top: 40px;
  }
  label {
    @extend .type-bsb;
    margin-bottom: 10px;
    display: block;
  }

  legend {
    @extend .type-hs;
    margin: 40px 0 20px;
  }

  input,
  select,
  textarea {
    @extend .type-bs;
    border-radius: $br;
    padding: 14px 18px;
    border: 1px solid $grey-space-cadet;
    background: $white;
    margin-bottom: 20px;
    width: 100%;

    + .help-text {
      margin-top: -10px;
      margin-bottom: 20px;
    }

    &::placeholder {
      color: $grey-manatee;
    }
    &:disabled {
      background: $grey-cultured-bone;
    }
  }

  .disclaimer {
    @extend .type-bs;
    margin-top: 20px;
    color: $grey-manatee;
  }
  .help-text {
    font-size: 12px;
    color: $grey-manatee;
  }
}
