// Sections can be added to any page in any order
section.panel {
  &.grey {
    color: $grey-space-cadet;
    background: $grey-cultured-bone;
  }
  &.red {
    color: white;
    background: $coral-red;
  }
  &.green {
    color: white;
    background: $green-say-yes;
  }
  &.dark-green,
  &.darkgreen {
    color: white;
    background: $green-bee-gee;
  }

  &.blue {
    color: white;
    background: $blue-all-in;
  }
  &.blue2 {
    color: white;
    background: $blue-faves;
  }

  &.cream {
    color: $grey-space-cadet;
    background: $cream-seashell;
  }

  &.champagne {
    color: $grey-space-cadet;
    background: $champagne-gold;
  }

  &.grey-space-cadet,
  .navyblue {
    color: white;
    background: $grey-space-cadet;
  }

  &.grad-yellow-1,
  &.paleyellow {
    background: #fff3c2;
  }
  &.grad-yellow-2 {
    background: darken(#fff3c2, 4%);
  }
  &.grad-yellow-3 {
    background: darken(#fff3c2, 8%);
  }
  &.grad-yellow-4 {
    background: darken(#fff3c2, 12%);
  }
  &.grad-yellow-5 {
    background: darken(#fff3c2, 16%);
  }

  &.promo-panel {
    padding: 30px 15px;
    @include tablet-portrait-up {
      padding: 120px 0;
    }
    p {
      font-size: 20px;
      @extend .type-bl;
    }
  }
}
