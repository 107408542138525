// Slide here
.lesson-slide.activity-image-meaning {
  &.student {
    background: $cream-seashell;
    color: $grey-space-cadet;
  }

  h2:not(.h3, .h4), .h2 {
    font-size: 3.6vw;
  }
  .right-col {
    align-items: center;
    display: flex;
  }

  // .row {
  //   width: 100%;
  // }

  .vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .student-input {
    align-items: baseline;
    text-align: left;
  }
  textarea {
    min-height: 20vh;
    width: 100%;
    margin: 20px 0 30px;
  }

  .team-badge {
    width: 10vh;
    height: 10vh;
    font-size: 60px;
    border-width: 2px;
    color: $green-say-yes;
    border-color: $green-say-yes;
    &.large {
      width: 20vh;
      height: 20vh;
      border-radius: 50%;
      border-width: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 15vh;
      margin: 0 5vw 3vh;
      font-weight: 900;
    }
  }
  .answer-wrap {
    display: flex;
    background: white;
    color: $grey-space-cadet;
    border-radius: 12px;
    align-items: center;
    width: calc(100% - 5vw);
    margin-bottom: 20px;
    margin-left: 5vw;
    .answer {
      padding: 20px 20px 20px 0;
      font-size: 22px;
    }
    &.stacked {
      flex-direction: column;
      padding: 20px;
      h3, .h3 {
        text-align: left;
        width: 100%;
      }
      .answer {
        padding: 10px 0 0;
        width: 100%;
      }
    }
  }

  // .answer {
  //   @extend .type-bl;
  //   background: white;
  //   border-radius: 16px;
  //   margin-bottom: 10px;
  //   padding: 25px 30px;
  // }

  .character-card {
    max-height: 80vh;
  }
  textarea.tall {
    min-height: 40vh;
  }
  h4.question,
  .h4.question {
    color: $coral-red;
    margin: 1vh 5vw;
    font-weight: 900;
  }
}
