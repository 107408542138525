// Slide here
.lesson-slide.activity-chalk-talk {
  .container {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .true-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-vert {
    flex-direction: column;
  }
  h1.quote {
    @extend h1;
    max-width: 800px;
    margin: 5vh auto;
    text-align: center;
    padding: 0 16px;
  }
  i {
    font-style: normal;
  }
  p.h4:not(.margin-bottom-5vh){
    margin-bottom: 0.5rem;
  }
  &.student {
    background: $cream-seashell;
    color: $grey-space-cadet;
  }
  .opening-question,
  .second-question {
    text-align: center;
    textarea {
      min-height: 20vh;
    }
  }
  .answer-cards {
    width: 100%;
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    max-height: 70vh;
    overflow-y: scroll;
    padding: 10px;
  }
  button.answer-card {
    color: $grey-space-cadet;
    text-align: left;
    font-weight: 400;
    cursor: pointer;
  }
  .answer-card {
    background: white;
    box-shadow: $box-shadow;
    border-radius: 30px;
    margin: 15px;
    padding: 15px;
    width: calc(50% - 30px);
    &.for {
      background: $green-bee-gee;
    }
    &.against {
      background: $coral-red;
    }
    &.summary {
      width: calc(50% - 30px);
      margin: 15px 15px 50px;
      padding: 30px;
      font-size: 23px;
      display: flex;
      flex-direction: column;
      align-items: baseline;

      .text {
        font-size: 23px;
        margin-bottom: 0;
      }
      .votes {
        background: rgba($grey-blackout, 10%);
        margin-top: 20px;
        width: auto;
        div {
          margin: 0 10px;
        }
      }
    }
    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .votes {
      display: flex;
      background-color: $grey-cultured-bone;
      padding: 6px 15px;
      border-radius: 20px;
      align-items: center;
      .count {
        font-size: 1rem;
      }
      .count:first-child {
        margin-right: 20px;
      }
    }
  }
  .answer {
    @extend .type-bl;
    padding: 20px;
    &.large {
      padding: 20px 0;
      font-size: 20px;
      font-weight: 700;
    }
  }
  .answer-tag {
    @extend h5;
    background: $green-bee-gee;
    border-radius: 20px;
    padding: 6px 15px;
    display: inline-block;
    color: white;
    font-weight: 700;
    &.against {
      background: $coral-red;
    }
  }
  .mask {
    background: $grey-blackout;
    width: 100vw;
    margin: -5vh -5vw;
    height: 100vh;
    padding: 5vw 5vh;
  }
  .Modal {
    max-width: 800px;
    margin: 0 auto;
    .group {
      text-align: left;
      margin-top: 10px;
      button,
      .btn {
        margin-right: 10px;
        font-size: 16px;
      }
    }
    .modal-inner {
      max-height: 70vh;
      overflow-y: scroll;
      padding: 0 10px 10px;
    }
  }
  .comment-form {
    margin: 20px 0;
    textarea {
      margin-bottom: 10px;
    }
  }
  .comment-header {
    margin: 30px 0 20px;
    position: relative;
    background: white;
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      width: calc(100% - 120px);
      left: 120px;
      background: $grey-loveheart;
      margin-top: -10px;
    }
  }
  .comment {
    display: flex;
    border: 1px solid $grey-loveheart;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    @extend .type-bl;
    .rating {
      margin-right: 10px;
    }
    .text {
    }
  }

  &.teacher.free-speech {
    .quote-wrap {
      max-width: 70vw;
      margin: 0 auto 2vh;
    }
    .quote {
      margin-bottom: 2vh;
      margin-top: 0;
    }
  }
  &.teacher .flex {
    width: 100%;
  }

  &.teacher.activity-michelle {
    .h2, .h3 {
      margin-bottom: 60px;
    }
    p {
      @extend h5;
      line-height: 1.4;
      margin-bottom: 15px;
    }
  }
}
