// Slide here
.lesson-slide.activity-support-map {
  background: $cream-seashell;
  color: $grey-space-cadet;
  .header {
    width: 100%;
    text-align: center;
    h3, .h3 {
      font-weight: 900;
    }
  }
  p.h5 {
    margin-bottom: 0.5rem;
  }
  .board-wrap {
    width: 100%;
    background: white url('/images/slides/activities/support-map/grey-target.svg') no-repeat center /
      cover;
    height: 70vh;
    border-radius: 40px;
    border: 4px dashed $grey-loveheart;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .board {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .avatar {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    box-shadow: $box-shadow;
    .avatar-preview {
      margin: -19.5% -0.5% -0.5%;
    }
  }
  .toolbar {
    display: flex;
    justify-content: center;

    .add-button {
      @extend h5;
      font-weight: 900;
      margin: 20px 10px;
      border-radius: 25px;
      background: white;
      border: 1px solid $grey-loveheart;
      padding: 10px 20px;
      line-height: 1;
      display: flex;
      color: $grey-space-cadet;
      cursor: pointer;

      i {
        width: 28px;
        height: 28px;
        border-radius: 15px;
        margin: -5px -15px -5px 20px;
        display: block;
        //background: transparent url('/images/icons/plus-isolated.svg') no-repeat center / cover;
      }
    }
  }
  .add-button i {
    background: $green-say-yes url('/images/icons/plus-isolated.svg') no-repeat center / cover;
  }

  .friends {
    &.supporter .face {
      background-color: $topic-1;
    }
    i {
      background-color: $topic-1;
    }
  }
  .school {
    &.supporter .face {
      background-color: $blue-sky-crayola;
    }
    i {
      background-color: $blue-sky-crayola;
    }
  }
  .community {
    &.supporter .face {
      background-color: $pink-you-and-me;
    }
    i {
      background-color: $pink-you-and-me;
    }
  }
  .other {
    &.supporter .face {
      background-color: $blue-faves;
    }
    i {
      background-color: $blue-faves;
    }
  }
  .supporter {
    text-align: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: move;
    animation: fadeIn 0.5s ease 0s 1 normal forwards;
    width: 150px;
    height: 100px;

    .face {
      background: $green-say-yes url('/images/icons/generic-face.svg') no-repeat center / 80%;
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .name {
      margin-top: 5px;
      font-weight: 900;
      font-size: 14px;
      user-select: none;
    }
  }

  .printout {
    background: white;
    padding: 20px;
    box-shadow: $box-shadow;
    width: 100%;
    margin-bottom: 3vh;
    h3, .h3 {
      text-align: center;
      font-weight: 900;
      margin-bottom: 10px;
      font-size: 16px;
    }
    h4, .h4 {
      margin: 20px 0 10px;
      font-size: 12px;
      font-weight: 700;
    }
    .external-organisations {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .organisation {
        width: 32%;
        padding: 4px;
        border: 0.5px solid $grey-independence;
        margin-bottom: 3%;
        border-radius: 4px;
        h5, .h5 {
          font-size: 10px;
          margin-bottom: 0;
        }
        .tags {
          line-height: 1;
        }
        .tag {
          margin: 0 2px 0px 0;
          padding: 2px 5px;
          font-size: 5px;
          border-width: 0.5px;
          white-space: nowrap;
        }
        p {
          margin: 5px 0;
          color: $grey-manatee;
          font-size: 5px;
        }
        .link {
          font-size: 5px;
          text-decoration: none;
          word-break: break-all;
        }
      }
    }
  }
}

.support-map-modal {
  .support-options {
    display: flex;
    flex-wrap: wrap;

    .option {
      background-color: transparent;
      color: $grey-space-cadet;
      font-weight: 400;
      border: 1px solid $grey-space-cadet;
      font-size: 14px;
      padding: 5px 20px;
      margin: 10px 10px 0px 0;
      border-radius: 30px;
      cursor: pointer;
      &.selected {
        box-shadow: 0 0 0 2px white, 0 0 0 5px $blue-all-in;
      }
    }
  }

  .white {
    border: 1px solid $grey-space-cadet;

    &.cancel {
      margin-top: 24px;
    }
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
