// Slide here
.submitted-screen {
  color: white;
  background-color: $grey-blackout;
  z-index: 99;
  position: relative;
  width: 100%;

  > .row {
    height: 100%;
    width: 100%;
    text-align: center;
  }

  .h4 {
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }
}
