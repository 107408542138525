// Slide here
.lesson-slide.title-slide {
  h1:not(.h2,.h3,.h4,.h5,.h6), .h1 {
    font-size: 5vw;
    font-weight: 900;
    max-width: 70%;
    opacity: 0;
    animation: fadeIn 1s ease 0s 1 forwards;
    position: relative;
    z-index: 4;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 1.5s ease 1s 1 forwards;
    pointer-events: none;
    //animation: name duration timing-function delay iteration-count direction fill-mode;
  }

  &.theme-cream {
    color: $grey-blackout;
    background-color: $cream-seashell;
  }

  &.theme-red {
    background-color: $coral-red;
    &:after {
      background: url(/images/slides/shapes/title-1-off-right.svg) no-repeat right / contain;
    }
  }
  &.theme-blue {
    background-color: $blue-all-in;
    &:after {
      background: url(/images/slides/shapes/title-2-off-right.svg) no-repeat right / contain;
    }
  }
  &.theme-green {
    background-color: $green-bee-gee;
    &:after {
      background: url(/images/slides/shapes/title-3-off-right.svg) no-repeat right / contain;
    }
  }

  &.title-image-slide {
    flex-direction: column;

    .source {
      margin-top: 24px;
      z-index: 2;
    }

    &.theme-green:after,
    &.theme-red:after {
      background-image: none;
    }

    h1:not(.h2,.h3,.h4,.h5,.h6), .h1 {
      max-width: none;
      font-size: 56px;
      margin-bottom: 0;
      @include vert-700-up {
        font-size: 66px;
      }
      @include vert-800-up {
        font-size: 72px;
      }
      @include ipad-only {
        font-size: 60px;
      }
      @include hd-up {
        font-size: 80px;
      }

    }

    .text--small h1,
    .text--small .h1 {
      font-size: 24px;
      @include vert-700-up {
        font-size: 46px;
      }
      @include ipad-only {
        font-size: 40px;
      }
      @include hd-up {
        font-size: 60px;
      }
    }

    .row-1 {
      display: block;
      margin-bottom: 0.5em;
    }


    &.trigger {
      h1:not(.h2), .h1 {
        max-width: none;
        font-size: 50px;
        @include vert-700-up {
          font-size: 56px;
        }
        @include vert-800-up {
        }
        @include ipad-only {
          font-size: 60px;
        }
        @include hd-up {
          font-size: 80px;
        }
      }
    }

    &.trigger {
      .boxed {
        background: rgba(black, 0.7);
        border-radius: 20px;
        padding: 20px 40px;
        display: flex;
        max-width: 70vw;
      }
      &.theme-cream .boxed {
        background: rgba(white, 0.9);
      }
    }

    &.has-box .text {
      background: rgba(white, 0.9);
      border-radius: 20px;
      padding: 20px 40px;
      display: flex;
      max-width: 70vw;
      width: auto;

      &.bottom-left {
        position: absolute;
        bottom: 10vh;
        h1, .h1 {
          margin: 0;
          width: auto !important;
        }
      }
    }

    .background-image {
      width: 100vw;
      height: 100vh;
      margin: -5vh -4vw;
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;

      &.middle {
        background-position: center;
      }

      &.center:not(.left):not(.right):not(.middle) {
        background-size: cover;
      }
    }

    .text {
      position: relative;
      z-index: 2;
      display: flex;
      width: 100%;
      &.left {
        margin-top: auto;
        margin-bottom: auto;
      }
      &.right {
        align-items: center;
        justify-content: flex-end;
      }
      &.bottom-left {
        margin-top: auto;
      }
      &.bottom {
        align-self: flex-start;
        margin-top: auto;
      }

      &.boxed {
        background: rgba(black, 0.7);
        border-radius: 20px;
        padding: 20px 40px;
        display: flex;
        max-width: 70vw;
        margin-left: auto;
        margin-right: auto;

        background: rgba(white, 0.9);
      }
    }
  }

  &.big-word-variant {
    .big-word {
      //text-align: right;
      float: right;
      font-size: 10vw;
    }
  }
}
