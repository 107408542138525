.lesson-slide.activity-social-posts-answers {
  background-color: $cream-seashell;
  color: $grey-space-cadet;

  text-align: center;

  .flex {
    width: 100%;
    justify-content: center;
    margin-top: 5vh;

    &.bottom-aligned {
      align-items: flex-end;
    }
  }

  .circle {
    width: 30vh;
    height: 30vh;
    border: 50%;
    margin: 2vh;
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $green-say-yes;
    border-radius: 50%;
    color: white;

    p {
      margin-bottom: 0;
    }

    &.incorrect {
      background: $coral-red;
    }

    .number {
      font-size: 15vh;
      font-weight: 900;
      line-height: 1;
      margin-top: -3vh;
      display: block;
    }
    h2, .h2 {
      margin: 0;
      margin-top: -0.5em;
      line-height: 1.2;
      display: block;
    }
  }

  .post {
    max-width: 15vw;
    margin: 30px;
    img {
      max-width: 100%;
    }
    .share-count {
      @extend h4;
      margin-top: 30px;
    }
  }
}
