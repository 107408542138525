// Slide here
.lesson-slide.activity-mark-it-up-slide {
  .heading-top {
    width: 100%;
    padding-top: 5vh;
    text-align: center;
    color: $champagne-gold;
  }

  .preview-window {
    max-width: 1200px;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto 15vh;
    position: absolute;
    top: 20vh;
    bottom: 5vh;
    height: 65vh;
    z-index: 1;
    overflow-y: scroll;

    .marker {
      position: absolute;
      background-color: $green-bee-gee;
      border: 2px solid white;
      border-radius: 30px;
      color: white;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1;
      font-weight: 900;
      width: 40px;
      height: 40px;

      &.group {
        text-align: center;
        padding-top: 6px;
        z-index: 10;
        margin-top: -30px;
        margin-left: -20px;
        span {
          z-index: 2;
          position: relative;
          font-size: 22px;
        }
      }
      &.official {
        width: 60px;
        height: 60px;
        margin-top: -23px;
        margin-left: -10px;
        padding: 5px 0 0 5px;

        &:before {
          bottom: -8px;
          width: 30px;
          height: 30px;
          margin-left: -15px;
        }

        i {
          width: 45px;
          height: 45px;
        }
      }
      &:before {
        position: absolute;
        content: '';
        background-color: $green-bee-gee;
        border: 2px solid white;
        border-top-width: 0;
        border-left-width: 0;
        width: 20px;
        height: 20px;
        left: 50%;
        bottom: -6px;
        transform: rotate(45deg);
        margin-left: -10px;
        z-index: 1;
      }
      &.under:before {
        transform: rotate(-135deg);
        bottom: auto;
        top: -10px;
      }

      i {
        background: url('/images/icons/trustworthy-invert.svg') no-repeat center / cover;
        width: 35px;
        height: 35px;
        display: inline-block;
        position: relative;
        z-index: 2;
      }

      &.wrong {
        background-color: $coral-red;
        &:before {
          background-color: $coral-red;
        }
        i {
          background: url('/images/icons/untrustworthy-invert.svg') no-repeat center / cover;
        }
      }
    }
  }

  .bottom-toolbar {
    position: absolute;
    color: $grey-space-cadet;
    background-color: white;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 10;
    box-shadow: $box-shadow;
    height: 15vh;
    align-items: center;

    h5, .h5 {
      margin-right: 20px;
      width: 50%;
    }

    p.h5 {
      margin-bottom: 0.5rem;
    }

    .btn {
      align-items: center;
      display: flex;

      .i {
        margin-right: 10px;
        box-shadow: $box-shadow;
        border-radius: 50%;
      }
    }
  }

  &.group-result {
    &.false {
      background-color: $coral-red;
    }
    &.true {
      background-color: $green-bee-gee;
    }
    .col-6 {
      text-align: center;
    }
    .wrap {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .answer {
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 3vh;
    }
  }

  .countdown-clock {
    color: white;
  }
}
