// Slide here
.lesson-slide.activity-poll {
  background-color: $cream-seashell;
  color: $grey-space-cadet;

  .options {
    margin-top: 40px;
  }

  .btn.option,
  .btn.Yes,
  .btn.No,
  .btn.duck,
  .btn.rabbit,
  .btn.Agree,
  .btn.Disagree {
    margin: 10px;
    padding: 13px 45px;

    &:focus {
      box-shadow: 0 0 0 2px $cream-seashell, 0 0 0 5px $blue-all-in;
    }
  }

  &.condensed {
    .btn {
      margin: 5px;
      padding: 13px 45px;
    }
  }

  h3, .h3 {
    @extend .col-md-9;
    margin-left: auto;
    margin-right: auto;
  }

  p.h4 {
    margin-bottom: 0.5rem;
  }

  &.theme-yes-no,
  &.theme-trust {
    .btn {
      background-color: $green-say-yes;
      &:hover {
        background-color: darken($green-say-yes, 10%);
      }

      &:last-child {
        background-color: $coral-red;
        &:hover {
          background-color: darken($coral-red, 10%);
        }
      }
    }
  }

  &.theme-many {
    .options {
      @extend .col-8;
      margin-left: auto;
      margin-right: auto;
      .option {
        width: 40%;
      }
    }
  }

  &.activity-poll-rights {
    .btn {
      margin: 5px;
       &.next-slide-btn {
        margin: 0;
       }
    }
  }

  &.class-diversity {
    .options {
      .btn:not(.green) {
        margin: 5px;
        padding: 10px 30px;
      }
    }
  }


}
