// Slide here
.lesson-slide.cover-slide {
  color: $grey-space-cadet;
  &.topic-1 {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: url(/images/slides/shapes/comb-fullscreen.svg) no-repeat center / cover;
      z-index: 1;
      opacity: 0;
      animation: fadeIn 3s ease 0s infinite alternate;
      pointer-events: none;
    }
  }

  .align-center {
    position: relative;
    z-index: 2;
    justify-content: center;
  }
  .topic {
    font-weight: 700;
    line-height: 1;
    font-size: 32px;
    background: $white;
    border-radius: 100px;
    display: inline-block;
    padding: 20px 30px;
  }
  h1, .h1 {
    font-size: 80px;
    font-size: 4vw;
    margin-top: 4vh;
  }
}
