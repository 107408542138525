// Slide here
// Pick a team activity, like in 7/8 S&P1
.lesson-slide.activity-create-your-team {
  background: $cream-seashell;
  color: $grey-space-cadet;

  p.h4 {
    margin-bottom: 0.5rem;
  }

  .copy {
    @extend h3;
    max-width: 60%;
  }

  .activity-area {
    margin-top: 20px;

    > .col-3 {
      display: flex;
      flex-direction: column;
    }
  }

  .pool .list {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 65vh;

    .choice {
      width: calc(25% - 10px);

      .name {
        margin-top: 10px;
      }
    }
  }

  .choice {
    background: $white;
    position: relative;
    border-radius: 20px;
    padding: 15px;
    @extend .h5;
    margin: 5px;
    &.dragging {
      // transform: rotate(8deg);
      box-shadow: $box-shadow;
    }

    .profile-pic {
      border-radius: 15px;
      transition: $transition;
    }

    .name {
      text-align: center;
      text-transform: capitalize;
    }

    .info-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background: $green-say-yes;
      border-radius: 30px;
      text-align: center;
      width: 35px;
      height: 35px;
      font-weight: bold;
      font-size: 14px;
      padding: 0;
      cursor: pointer;
    }
  }
  .column-title {
    display: none;
  }

  .list {
    min-height: 100px;
    width: 100%;
    height: 100%;
  }

  .bucket {
    @extend .h5;
    border: 2px dashed $grey-space-cadet;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    border-radius: 20px;
    min-height: 15vh;
    margin: auto;

    &.is-full {
      border-style: solid;
      border-color: $blue-all-in;
      .column-title {
        position: relative;
      }
    }
    .column-title {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }
  .bottom {
    margin-top: 30px;
  }

  .bucket .choice {
    z-index: 2;
    display: flex;
    align-items: center;
    height: 55px;
    padding: 10px;

    .name {
      text-align: left;
      font-size: 13px;
      padding-right: 45px;
    }

    .profile-pic {
      width: 35px;
      border-radius: 20px;
      margin-right: 10px;
    }
  }

  .choice.dragging {
    border: 4px solid $blue-all-in;
  }
  .activity-area + .bottom {
    position: relative;
  }

  .finish-activity-btn {
    width: auto;
  }

  &.step-3 {
    flex-direction: column;
    justify-content: center;

    h1.h2 {
      margin-bottom: 0.5rem;
    }

    span.h4 {
      display: block;
      padding-top: 0.9286em;
    }

    .choices {
      display: flex;
      flex-wrap: wrap;
    }
    .choice {
      background: white;
      position: relative;
      border-radius: 20px;
      padding: 15px;
      margin: 5px;
      width: calc(50% - 10px);

      img {
        border-radius: 15px;
      }
      .name {
        @extend h5;
        margin-top: 10px;
      }
      .informed {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $grey-space-cadet;
        background: $champagne-gold;
        border-radius: 30px;
        text-align: center;
        width: 55px;
        height: 55px;
        font-weight: bold;
        font-size: 10px;
        font-weight: 700;
        line-height: 1;
      }
    }
  }
}
