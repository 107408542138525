// Buttons

.btn,
button {
  background: $grey-space-cadet;
  @extend %ui-navigation;
  border-radius: $br;
  font-size: 16px;
  color: $white;
  padding: 13px 37px;
  transition: $transition;
  border: none;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #fcf6ee, 0 0 0 5px $blue-all-in;
  }

  &.blue-all-in {
    background: $blue-all-in;
    color: $white;
    &:hover {
      background-color: darken($blue-all-in, 15%);
    }
  }

  &.grey-feedbacker {
    background: $grey-feedbacker;
    color: $grey-space-cadet;
    &:hover {
      background-color: darken($grey-feedbacker, 10%);
    }
  }

  &.green {
    background: $green-say-yes;
    color: $white;
    &:hover {
      background-color: lighten($green-say-yes, 10%);
    }
    &:active {
      background-color: darken($green-say-yes, 15%);
    }
  }

  &.dark-green {
    background: $green-bee-gee;
    color: $white;
    &:hover {
      background-color: darken($green-bee-gee, 15%);
    }
  }

  &.red {
    background: $coral-red;
    color: $white;
    &:hover {
      background-color: lighten($coral-red, 10%);
    }
    &:active {
      background-color: darken($coral-red, 15%);
    }
  }

  &.white {
    background: $white;
    color: $grey-space-cadet;
    &:hover {
      background-color: $grey-feedbacker;
    }
  }

  &.selected {
    background-color: $blue-sky-crayola;
    color: $grey-space-cadet;
    &:hover {
      background-color: darken($blue-sky-crayola, 10%);
    }
  }

  &.blue-sky-crayola {
    background: $blue-sky-crayola;
    color: $grey-space-cadet;
    &:hover {
      background-color: darken($blue-sky-crayola, 10%);
    }
  }

  &.shadowed {
    box-shadow: $box-shadow;
    &:focus {
      box-shadow: $box-shadow, 0 0 0 2px #fcf6ee, 0 0 0 5px $blue-all-in;
    }
  }

  &.bordered {
    border: 1px solid $grey-space-cadet;
  }

  &:hover {
    background-color: lighten($grey-space-cadet, 10%);
  }

  &.large {
    font-size: 20px;
  }

  &.small {
    padding: 9px 32px;
  }

  &.round {
    background-color: $green-say-yes;
    border-radius: $br-large;

    &:hover {
      background-color: lighten($green-say-yes, 20%);
    }
  }

  &.plus {
    position: relative;
    padding-right: 128px;
    padding-left: 20px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 20px;
      background: url('/images/icons/plus.svg') no-repeat center / contain;
      width: 26px;
      height: 26px;
      top: 50%;
      margin-top: -13px;
    }
  }

  &.thin {
    padding: 13px 16px;
  }

  &:disabled,
  &.disabled {
    cursor: default;
    background-color: $grey-cultured-bone;
    color: $grey-manatee;
    border-color: $grey-manatee;
    &:hover {
      background-color: $grey-cultured-bone;
      border-color: $grey-manatee;
    }
  }

  &.next-slide-btn {
    background-color: $green-say-yes;
    border-radius: 50px;
    position: absolute;
    bottom: 5%;
    right: 5%;
    padding: 18px 30px;
    z-index: 9999;
    &:disabled {
      background-color: $grey-loveheart;
      pointer-events: none;
    }
  }

  i.trustworthy,
  i.untrustworthy {
    margin-right: 10px;
  }
}

.btn-group {
  margin-top: 30px;
  text-align: center;
  .btn,
  button {
    margin: 0 5px;
    padding: 13px 57px;
  }
}

.btn-pair {
  margin: 0 0 10px;
  .btn,
  button {
    margin: 0 5px;
    padding: 13px 57px;
  }
}
.btn-list {
  .btn,
  button {
    margin: 0 0 4px;
    display: block;
    padding: 10px 20px;
    width: 100%;
    text-align: left;
  }
}
.btn-list-inline {
  .btn,
  button {
    margin: 0 4px 4px;
    display: inline-block;
    padding: 10px 20px;
  }
}
