// Sections can be added to any page in any order
section.strapi-panel {
  &.hero {
    &.no-overlap {
      margin-top: 0;
    }
    .container {
      padding: 0;
    }
    .copy {
      padding: 90px 0 60px;
    }
  }

  &.glossary {
    padding: 30px 0;
  }

  .letter-group {
    padding-top: 20px;
  }
}

.glossary-nav {
  position: sticky;
  top: 0;
  background-color: white;
  ul {
    display: flex;
    margin: 0;
    padding: 10px 0;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    @include pc-laptop-up {
      flex-wrap: nowrap;
    }
    li {
      list-style: none;

      @extend h4;
      font-weight: 900;
      margin: 5px 10px;
      a {
        text-decoration: none;
      }
    }
  }
}
