// Sections can be added to any page in any order
section.how-it-works-hero {
  background: #dbf0ff;
  margin-top: -50px;
  padding: 50px 15px 0;

  @include tablet-portrait-up {
    margin-top: -150px;
    padding: 150px 0 0;
  }

  .bottom-graphic {
    background: url('/images/website/how-it-works.svg') no-repeat bottom center / contain;
    height: 50vh;
    max-height: 500px;
    width: 100%;
    margin: auto;

    @include tablet-portrait-up {
      height: 50vh;
    }
  }
  .subtext {
    font-size: 18px;
    line-height: 1.5;
    max-width: 720px;
    margin: auto;
  }

  .container {
    // align-items: center;
    // display: flex;
    // max-height: 700px;
    // justify-content: center;
  }
  h1, .h1 {
    line-height: 1.1;
    font-size: 42px;

    @include tablet-portrait-up {
      font-size: 58px;
    }
    @include tablet-landscape-up {
      font-size: 78px;
    }

    span {
      display: block;
    }
  }

  .copy {
    padding: 90px 20px 10px 0px;
    margin-bottom: -240px;
    @extend .type-bl;
    z-index: 10;

    p,
    h1, .h1 {
      background: #dbf0ff;
    }
  }
}

section.how-it-works-timeline {
  background-color: #fff3c2;
  position: relative;
  padding: 80px 0;
  display: flex;
  flex-direction: column;

  .timeline-graphic {
    position: absolute;
    left: 0;
    right: 0;

    max-width: 90%;
    margin: auto;
    display: none;

    @include tablet-landscape-up {
      display: block;
      max-width: 90vw;
      top: -230px;
    }
    @include pc-laptop-up {
      max-width: 1080px;
      top: -300px;
    }
  }
  .step {
    position: relative;
    padding: 15px;
    @include tablet-landscape-up {
      padding: 50px 0;
    }
    h4, .h4 {
      font-weight: 900;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
    }
  }
  .timeline {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
  .step {
    position: relative;
    display: flex;
    @include tablet-landscape-up {
      display: block;
    }

    .number {
      //position: absolute;
      width: 30px;
      min-width: 30px;
      margin-right: 10px;

      height: 30px;
      border-radius: 50%;
      border: 2px solid $grey-space-cadet;
      background-color: white;
      font-weight: 900;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include tablet-landscape-up {
        display: none;
      }
    }
  }

  .step-1 {
    @include tablet-landscape-up {
      max-width: 350px;
      margin: -50px 0 0 110px;
    }
    @include pc-laptop-up {
      max-width: 400px;
      margin: 0px 0 0 220px;
    }
  }
  .step-2 {
    @include tablet-landscape-up {
      text-align: right;
      max-width: 360px;
      margin: 180px -70px 0 0;
    }
    @include pc-laptop-up {
      max-width: 430px;
      margin: 180px -70px 0 0;
    }
  }
  .step-3 {
    @include tablet-landscape-up {
      max-width: 370px;
      margin: 20px 0 0 140px;
    }
    @include pc-laptop-up {
      max-width: 480px;
      margin: 80px 0 0 270px;
    }
  }
  .step-4 {
    @include tablet-landscape-up {
      text-align: right;
      max-width: 470px;
      margin: 290px 0px 0 -100px;
    }
    @include pc-laptop-up {
      margin: 390px 0px 0 -100px;
    }
  }
}
