// Countdown clock on student screen

.countdown-clock {
  position: absolute;
  right: 5vw;
  top: 5vh;
  @extend h4;
  color: $white;
  font-weight: 900;
  width: 120px;
  height: 120px;
  //border: 3px solid $blue-all-in;
  //border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  flex-shrink: 0;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
    height: 120px;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }
  svg circle.base {
    stroke: $grey-loveheart;
    stroke-width: 3px;
    fill: none;
  }

  svg circle.remaining {
    stroke-dasharray: 385px; // circumference + width*2
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 4px;
    stroke: $blue-all-in;
    fill: none;
    animation: countdown 20s linear infinite forwards;
  }

  .lesson-slide.activity-chalk-talk.student &,
  .lesson-slide.activity-cyberbullying &,
  .lesson-slide.activity-drag-drop &,
  .lesson-slide.activity-image-meaning &,
  .lesson-slide.activity-image-meaning &,
  .lesson-slide.activity-image-reveal &,
  .lesson-slide.activity-match-scenario &,
  .lesson-slide.activity-observations-slide &,
  .lesson-slide.activity-poll &,
  .lesson-slide.activity-think-feel-care &,
  .lesson-slide.activity-support-map &,
  .lesson-slide.activity-video-moments &,
  .lesson-slide.reflection-slide &,
  .lesson-slide.website-review-student.website-preview-slide & {
    color: $grey-space-cadet;
  }

  .lesson-slide.activity-video-moments .step-overlay ~ & {
    color: $white;
  }

  //different style for teacher screen
  .activity-underway-slide &,
  .conclusion-slide & {
    position: relative;
    width: 240px;
    height: 240px;
    right: auto;
    top: auto;
    margin: 0 auto;
    @extend .h2;

    &.expired {
      svg circle.base {
        stroke: $coral-red;
      }
      svg circle.remaining {
        animation: none;
        stroke-width: 0;
      }
    }

    svg {
      width: 240px;
      height: 240px;
      circle.base {
        stroke-width: 8px;
        stroke: white;
      }
      circle.remaining {
        stroke-dasharray: 704px; // circumference (754) + width*2
        stroke-width: 10px;
        animation: countdown-large 1s linear infinite forwards;
      }
    }
  }
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: -385px;
  }
}
@keyframes countdown-large {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: -704px;
  }
}
