// Slide here
.lesson-slide.activity-dog-breed-answers {
  background-color: $cream-seashell;
  color: $grey-space-cadet;

  text-align: center;

  .flex {
    width: 100%;
    justify-content: center;
    margin-top: 5vh;

    &.bottom-aligned {
      align-items: flex-end;
    }
  }

  h3, .h3 {
    font-weight: 900;
    margin-bottom: 30px;
  }
  h4, .h4 {
    font-size: 24px;
  }
  .dog {
    background-color: white;
    margin: 30px;
    box-shadow: $box-shadow;
    position: relative;
    padding-bottom: 10px;
  }
  .discrimination {
    margin: 10px 10px 30px;
    padding: 20px;
    border-radius: 20px;
    height: 45vh;
    display: flex;
  }
  .score {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $green-say-yes;
    color: white;
    text-align: center;
    font-weight: 900;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.red {
      background-color: $coral-red;
    }
    p {
      margin-bottom: 0;
    }
  }
}
