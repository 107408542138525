// Sections can be added to any page in any order
section.hero-cta {
  background: #fff3c2;
  margin-top: -150px;
  padding-top: 150px;
  text-align: center;
  //color: white;
  //padding: 170px 0 150px;
  .container {
    // align-items: center;
    // display: flex;
    // max-height: 700px;
    // justify-content: center;
  }
  h1, .h1 {
    line-height: 1.1;
    font-size: 38px;

    margin: 40px 0;

    @include tablet-portrait-up {
      font-size: 58px;
      margin: 0 0 0.5em;
    }
    @include tablet-landscape-up {
      font-size: 78px;
    }

    span {
      display: block;
    }
    .top {
      transform: rotate(-2.5deg);
      //  padding-right: 150px;
    }
    .bottom {
      color: $blue-all-in;
      //    padding-left: 150px;
    }

    @include tablet-landscape-up {
      .top {
        padding-right: 150px;
      }
      .bottom {
        padding-left: 150px;
      }
    }
  }
  .subtext {
    font-size: 18px;
    line-height: 1.5;
    max-width: 720px;
    margin: auto;
  }
  .copy {
    padding: 90px 0 50px;
    margin: auto;
    @extend .type-bl;

    @include mobile-only {
      width: 100%;
    }
  }
  .btn {
    margin-top: 40px;
    font-size: 22px;
    background-color: $blue-all-in;
    padding: 12px 72px;
    border-radius: 4px;
    &:hover {
      background-color: lighten($blue-all-in, 10%);
      color: white;
    }
  }
  .disclaimer {
    @extend .type-bs;
    margin-top: 10px;
    font-weight: 700;
    color: $grey-space-cadet;
  }

  .bottom-graphic {
    background: url('/images/website/hero-image.png') no-repeat center bottom / contain;
    height: 50vh;
    max-height: 80px;
    width: 100%;
    margin: auto;

    @include tablet-portrait-up {
      max-height: 320px;
    }
  }
}
