// Slide here
.lesson-slide.activity-video-moments {
  background-color: $grey-feedbacker;
  color: $grey-space-cadet;

  &.cream {
    background-color: #f4efe8;
  }

  p.h4 {
    margin-bottom: 0.5rem;
  }

  .video {
    position: absolute;
    top: 0;
    //bottom: 0;
    //left: 0;
    right: 0;
    z-index: 1;
    //width: 100vw;
    height: 100vh;
  }
  .step {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    &.left {
      justify-content: flex-start;
    }

    p {
      @extend h4;
      color: $grey-manatee;
    }
  }

  .step-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: white;
    background: $grey-blackout;
    z-index: 10;
    display: flex;
    text-align: center;
    .row {
      // height: 100%;
    }
    .btn {
      margin-left: 10px;
      margin-right: 10px;
    }
    .finish {
      background-color: $green-say-yes;
    }
  }
  .play-button {
    margin-top: 30px;
    background-color: $blue-all-in;
  }
  .btn.spacer {
    visibility: hidden;
  }

  .tracker {
    width: 80vw;
    position: absolute;
    bottom: 0;
    margin: auto;
    background: $grey-loveheart;
    padding: 5px;
    border-radius: 80px;
    display: flex;
    left: 0;
    right: 0;
    justify-content: space-between;
    .rail {
      background-color: white;
      height: 3px;
      margin: 36px;
      margin-right: 0;
      border-radius: 2px;
      width: calc(100% - 300px);
      position: relative;

      .bar {
        width: 0;
        height: 3px;
        border-radius: 3px;
        background-color: $grey-space-cadet;
        transition: 0.8s ease;
      }

      .marker {
        width: 34px;
        height: 34px;
        background: url('/images/icons/intervene.svg') no-repeat center / contain;
        position: absolute;
        top: -16px;
      }
    }
  }
  .intervene-btn {
    background: white;
    border-radius: 60px;
    padding: 8px;
    display: flex;
    align-items: center;
    transition: $transition;
    cursor: pointer;
    margin-left: 36px;

    &:hover {
      background-color: $coral-red;
    }

    .intervene-icon {
      background: url('/images/icons/intervene.svg') no-repeat center / contain;
      width: 60px;
      height: 60px;
      pointer-events: none;
    }

    .intervene-text {
      @extend h4;
      margin: 0 15px;
      color: $grey-space-cadet;
      pointer-events: none;
    }
  }

  .centered {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .team-badge {
    width: 30vh;
    height: 30vh;
    border-radius: 50%;
    border: 9px solid $grey-space-cadet;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 20vh;
    color: $grey-space-cadet;
    margin: 3vh auto;
    font-weight: 900;

    &.ready {
      color: $green-say-yes;
      border-color: transparent;
      background-color: rgba($champagne-gold, 20%);
    }
  }
}
