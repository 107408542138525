// Sections can be added to any page in any order
section.one-col-text {
  padding: 80px 0;
  font-size: 20px;
  h3, .h3 {
    font-weight: 900;
    margin: 1em 0 0.5em;
  }
  .h4 {
    font-weight: 700;
    margin-top: 0;
  }
  .progress-bars .h4 {
    margin: 1em 0 0.25em;
  }
  p {
    margin-top: 20px;
  }

  dt {
    margin-top: 15px;
  }

  dd {
    font-size: 17px;
  }

  p,
  ul {
    margin-bottom: 1em;
  }
  li {
    margin-bottom: 0.3em;
  }
  .row {
    justify-content: center;
  }
  .copy {
    @extend .col-7;
    margin: 0 auto;
    //text-align: center;
  }
  &.first {
    padding-top: 150px;
  }
  &.last {
    padding-bottom: 150px;
  }
}
