// Table

.table {
  margin-top: 40px;
  .table-header {
    background: $grey-feedbacker;
    border-radius: 8px 8px 0 0;
    color: $grey-manatee;
    font-weight: 700;
  }

  .row {
    border: 1px solid $grey-feedbacker;
    border-top-width: 0;
  }

  .col {
    padding: 25px;
  }

  &.bold-first {
    .col:first-child {
      font-weight: 700;
    }
  }
}
