// Footer

.feedback-icon {
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: $blue-all-in;
  left: 1.5vw;
  cursor: pointer;
  bottom: 2vh;
  box-shadow: $box-shadow;
  transition: 0.3s ease-in-out;
  user-select: none;
  z-index: 10;
  padding: 0;
  &:hover {
    background: lighten($blue-all-in, 10%);
  }

  i {
    background: url('/images/icons/feedback.svg') no-repeat center / contain;
    pointer-events: none;
    display: block;
    width: 30px;
    height: 30px;
    margin-left: 12px;
  }

  .feedback-popup {
    background: white;
    color: $grey-space-cadet;
    position: absolute;
    left: 0;
    bottom: 60px;
    border-radius: 20px 20px 20px 0;
    font-size: 18px;
    padding: 20px;
    min-width: 300px;
    box-shadow: $box-shadow;

    .btn {
      background: $blue-all-in;
      &:hover {
        color: white;
        background: lighten($blue-all-in, 10%);
      }
    }
  }
}
