// Slide here
.lesson-slide.word-cloud-slide {
  background-color: $cream-seashell;
  color: $grey-space-cadet;
  h1, .h1 {
    margin-bottom: 40px;
  }

  img {
    max-width: 80%;
  }
}
