// Tag

.tag {
  font-size: 11px;
  border: 1px solid $grey-space-cadet;
  border-radius: $br-large;
  font-weight: 700;
  line-height: 1;
  padding: 6px 10px;
  display: inline-block;
  letter-spacing: 0.05em;

  &.white {
    border-color: $white;
    background: $white;
  }

  &.grey-feedbacker {
    border-color: $grey-feedbacker;
    background: $grey-feedbacker;
  }

  @include topic-colours();

  &.big {
    padding: 11px 15px;
  }
}

a.tag {
  text-decoration: none;
  color: $grey-space-cadet;
  &:hover {
    background: $grey-feedbacker;
  }
}
