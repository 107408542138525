// Slide here
.lesson-slide.illustration-slide {
  background-color: $cream-seashell;
  color: $grey-space-cadet;

  .image.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
  }
  .floating-label {
    background-color: $grey-space-cadet;
    color: white;
    display: inline-block;
    position: absolute;
    bottom: 5vh;

    left: 5vw;
    line-height: 1;
    padding: 20px 40px;
  }
}
