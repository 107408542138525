// Header Navigation
.module-tiles {
  ul {
    padding: 0;
    li {
      list-style: none;
      display: flex;
      align-items: stretch;
    }
  }
 .h3 {
    padding: 80px 0 60px;
  }
}
.module-tile {
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: $grey-space-cadet;
  text-decoration: none;
  //justify-content: space-between;
  box-shadow: 0 0 transparent;
  transition: $transition;
  position: relative;
  //cursor: pointer;

  @include topic-colours();

  // &:hover {
  //   box-shadow: $box-shadow;
  //   color: $grey-space-cadet;
  //   transform: scale(1.05);
  // }

  .copy {
    margin-top: 35px;
  }

  .bottom {
    margin-top: auto;
  }

  .btn-row {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .left {
      display: flex;
      .btn {
        margin-right: 10px;
        background-color: rgba(white, 0.4);
        // border: 1px solid $blue-all-in;
        color: $grey-space-cadet;
        padding: 13px 17px;
        &:hover {
          background-color: rgba(white, 0.87);
        }
      }
    }
    button {
      text-align: left;
    }
  }

  .overlay {
    opacity: 0;
    transition: $transition;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 12px;
    pointer-events: none;
    z-index: 10;
    .tick {
      background-color: $blue-all-in;
      width: 57px;
      height: 57px;
      position: absolute;
      right: 18px;
      top: 18px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: $transition;
      opacity: 0;
    }

    .completed-runner {
      background-color: $green-say-yes;
      font-size: 18px;
      margin: 0;
      color: $white;
      font-weight: 900;
      padding: 20px 25px;
      border-radius: 12px 12px 0 0;
    }
  }

  &.selected {
    .overlay,
    .tick {
      opacity: 1;
    }
  }
  &.completed {
    .overlay {
      opacity: 1;
    }
  }
}

//custom stuff for the prepare screen here
.prepare-session .col-6 {
  perspective: 1000px;

  &:hover .module-tile {
    transform: rotateY(180deg);
  }

  .module-tile {
    transform-style: preserve-3d;
    cursor: default;
  }

  .front {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .front,
  .back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    position: relative;
    z-index: 1;
  }

  .selected .back {
    background-color: $coral-red;
  }

  .back {
    background-color: $blue-all-in;
    transition: $transition;
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 40px;
    align-items: center;
    justify-content: center;

    a {
      @extend .type-ui;
      color: white;
      margin: 30px 0 0;
      text-decoration: none;
      &:hover {
        border-bottom: 1px solid white;
      }
    }

    .btn {
      cursor: pointer;
      margin-bottom: 30px;
    }
  }
}
