// Slide here
.lesson-slide.nps-slide {
  background-color: $green-say-yes;
  color: $grey-space-cadet;

  .instructions {
    font-size: 24px;
  }
  .options {
    display: flex;
    justify-content: center;
  }
  .nps-option {
    margin: 20px 10px 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    background-color: transparent;
    color: $grey-space-cadet;
    font-weight: 400;
    flex: 0 0 auto;

    .icon {
      background: $champagne-gold;
      border-radius: 50%;
      margin-bottom: 10px;
      border: 2px solid transparent;
    }
    &:hover {
      text-decoration: underline;
      .icon {
        border-color: $grey-space-cadet;
        border-color: darken($champagne-gold, 25%);
        background: darken($champagne-gold, 8%);
      }
    }
    &.selected {
      .icon {
        background: darken($champagne-gold, 15%);
        border-color: darken($champagne-gold, 35%);
      }
      .option-label {
        font-weight: bold;
      }
    }
    .option-label {
      font-size: 18px;
      text-transform: capitalize;
    }
  }

  textarea {
    width: 100%;
    margin: 10px auto 20px;
    border-radius: 5px;
    min-height: 100px;
    border: 2px solid darken($green-say-yes, 20%);
    padding: 10px 15px;
    font-size: 16px;
    background-color: $cream-seashell;
  }
  .frame {
    border: 1px solid darken($green-say-yes, 10%);
    border-radius: 10px;
    padding: 20px 30px;
    margin-top: 20px;
    p {
      font-size: 16px;
    }
    label {
      font-size: 16px;
      font-weight: bold;
      display: block;
      margin: 20px 0 0;
    }
  }
}
