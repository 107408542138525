// Slide here
.lesson-slide.video-slide {
  .video-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    iframe,
    video {
      width: 70vw;
      height: 70vh;
      margin-top: -32px;
    }
  }

  .video-citation {
   margin-top: -60px
  }

  &.fullscreen {
    padding: 0;
    iframe,
    video {
      width: 100vw;
      height: 100vh;
      margin-top: 0;
    }
  }

  &.video-title-slide {
    h1, .h1 {
      position: absolute;
      color: $grey-space-cadet;
      background-color: $cream-seashell;
      border-radius: 20px;
      padding: 20px 40px;
      &.top-left {
        top: 5vh;
        left: 5vw;
      }
      &.left {
        left: 5vw;
        top: 40vh;
      }
      &.bottom {
        bottom: 5vh;
        left: 5vw;
        right: 5vw;
      }

      font-size: 40px;
      @include vert-700-up {
        font-size: 50px;
      }

      @include vert-800-up {
        font-size: 56px;
      }
    }
  }

  &.theme-dirty-cream {
    background-color: #f6efe8;
  }
}
