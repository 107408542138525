// Forms

.student-input {
  fieldset {
    margin-top: 40px;
  }
  label {
    @extend h4;
    margin-bottom: 10px;
    display: block;

    &.small {
      font-size: 20px;
    }
    &.checkbox-label {
      cursor: pointer;
    }
  }

  legend {
    @extend .type-hs;
    margin: 40px 0 20px;
  }

  input,
  select,
  textarea {
    @extend .type-bl;
    border-radius: $br;
    padding: 14px 18px;
    border: 1px solid $grey-space-cadet;
    background: $white;
    margin: 20px 0 30px;
    width: 100%;

    + .help-text {
      margin-top: -10px;
      margin-bottom: 20px;
    }

    &::placeholder {
      color: $grey-manatee;
    }

    &[type='checkbox'] {
      width: auto;
      margin: 0 10px 0 0;
      cursor: pointer;
    }
  }
  .range-slider {
    -webkit-appearance: none;
    padding: 2px 0;
    width: 100%;
    height: 3px;
    border-radius: 25px;
    background: $blue-all-in;
    border: none;
    outline-color: $blue-all-in;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $blue-all-in;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 31px;
      height: 31px;
      border-radius: 50%;
      background: $blue-all-in;
      cursor: pointer;
    }
  }

  .disclaimer {
    @extend .type-bs;
    margin-top: 20px;
    color: $grey-manatee;
  }
  .help-text {
    font-size: 12px;
    color: $grey-manatee;
  }

  button:disabled {
    background: rgba($grey-space-cadet, 40%);
    color: $white;
  }
}
