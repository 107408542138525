// Sections can be added to any page in any order
section.hero {
  background: #fff3c2;
  margin-top: -150px;

  text-align: center;
  //color: white;
  //padding: 170px 0 150px;
  .container {
    padding: 150px 20px 120px 20px;
    // align-items: center;
    // display: flex;
    // max-height: 700px;
    // justify-content: center;
  }
  h1.primary,
  .h1.primary {
    line-height: 1.1;
    font-size: 78px;
  }
  .subtext {
    font-size: 26px;
    line-height: 1.5;
    max-width: 720px;
    margin: auto;
    font-weight: 900;
  }
  .copy {
    padding: 90px 0 50px;
    margin: auto;
    @extend .type-bl;
  }

  &.secondary-page {
    h1, .h1 {
      margin-bottom: 10px;
    }
    .subtext {
      font-size: 22px;
    }
  }

  &.our-mission {
    background: $topic-discrimination;
    text-align: left;

    .container {
      background: url('/images/public-website/header-diverse-friends.svg') no-repeat bottom center /
        contain;
    }
    .copy {
      margin: 0;
    }
  }

  &.about {
    background: $topic-online;
    text-align: left;

    .container {
      background: url('/images/public-website/header-about.svg') no-repeat bottom center / contain;
    }
    .copy {
      margin: 0;
    }
  }

  &.course-outline {
    background: $topic-online;
    text-align: left;

    .container {
      background: url('/images/public-website/header-course.svg') no-repeat bottom center / contain;
    }
    .copy {
      margin: 0;
    }
  }
}
