// Sections can be added to any page in any order
section.program-highlevel {
  background: $grey-space-cadet;
  color: white;
  font-size: 18px;
  padding: 30px 15px;
  @include tablet-portrait-up {
    padding: 120px 0;
  }

  .bottom-justified {
    margin-top: 30px;
    display: flex;
    align-items: flex-end;

    > div {
      // display: flex;
      // align-items: flex-end;
    }

    .selling-card {
      background: white;
      color: $grey-space-cadet;
      border-radius: 16px 16px 20px 20px;
      padding: 15px 20px;
      margin: 10px 0px 5px;

      @include tablet-portrait-up {
        border-radius: 36px 36px 40px 40px;
        padding: 30px 50px;
        margin: 10px 10px 30px;
      }

      h4,
      .h4 {
        font-size: 24px;
        font-weight: 700;
      }
      .bottom-cta {
        margin: 15px -20px -15px;
        display: block;
        background: $blue-all-in;
        color: white;
        text-decoration: none;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        padding: 15px 20px;
        border-radius: 0 0 16px 16px;

        @include tablet-portrait-up {
          margin: 30px -50px -30px;
          border-radius: 0 0 36px 36px;
          padding: 25px 20px;
        }

        &:hover {
          background: lighten($blue-all-in, 10%);
        }
      }
    }
  }
  .signoff {
    text-align: center;
    padding: 40px 0;
  }
  .yellow {
    color: $yellow;
  }
  .image-bench {
    text-align: center;
    margin-bottom: 70px;
  }
  .classic-link {
    color: white;
  }
  .flex-top-and-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

section.proof-points {
  text-align: center;
  padding: 30px 15px;
  @include tablet-portrait-up {
    padding: 120px 0;
  }

  h3,
  .h3 {
    font-weight: 800;
  }
  .copy p {
    margin-top: 20px;
    font-size: 20px;
    @extend .type-bl;
    margin: 20px auto;
    @include tablet-portrait-up {
      width: 60%;
    }
  }
  .offwhite-boxout {
    background: $grey-cultured-bone;
    margin-top: 30px;
    border-radius: 16px;
    padding: 0;
    @include tablet-portrait-up {
      margin-top: 60px;
      border-radius: 60px;
      padding: 70px 0;
    }
    &.row {
      display: block;
      @include tablet-portrait-up {
        display: flex;
      }
    }
    .col {
      padding: 15px;

      @include tablet-portrait-up {
        padding: 20px 40px;
        border-left: 1px solid darken($grey-feedbacker, 5%);
      }
      &:first-child {
        border-left: none;
      }

      h4,
      .h4 {
        font-weight: 800;
        font-size: 26px;
        margin-bottom: 20px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}

section.sign-in-panel {
  padding: 30px 15px;

  h3,
  .h3 {
    font-weight: 800;
  }

  .copy p {
    margin-top: 20px;
    font-size: 20px;
    @extend .type-bl;
    margin: 20px auto;
    @include tablet-portrait-up {
      width: 60%;
    }
  }

  .offwhite-boxout {
    background: $grey-cultured-bone;
    border-radius: 16px;
    padding: 44px 20px;
    @include tablet-portrait-up {
      border-radius: 60px;
      padding: 44px 20px;
    }
    &.row {
      display: block;
      @include tablet-portrait-up {
        display: flex;
      }
    }
    .col {
      padding: 15px;

      @include tablet-portrait-up {
        padding: 20px 40px;
        border-left: 1px solid darken($grey-feedbacker, 5%);
      }
      &:first-child {
        border-left: none;
      }

      h4,
      .h4 {
        font-weight: 800;
        font-size: 26px;
        margin-bottom: 20px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}

section.testamonials {
  background: $coral-red;
  color: white;
  font-size: 18px;

  padding: 30px 15px;
  @include tablet-portrait-up {
    padding: 120px 0;
  }

  .three-col-dividers {
    display: block;
    @include tablet-portrait-up {
      display: flex;
      margin: 80px 0 0;
    }
    @include pc-laptop-up {
      margin: 80px -50px 0;
    }
  }

  .testamonial {
    padding: 15px;

    @include tablet-portrait-up {
      padding: 0 50px 20px;
      border-left: 1px solid rgba(white, 20%);
    }
    &:first-child {
      border-left: none;
    }
    img {
      display: block;
      margin: auto;
    }
    cite {
      display: block;
      margin: 20px 0 20px;
      font-size: 15px;
      font-weight: 800;
      font-style: normal;
    }
    blockquote {
      margin: 0;
      padding: 0;
      font-size: 20px;
      font-weight: 800;
    }
  }
}
section.cta-banner {
  text-align: center;
  background: $grey-space-cadet;
  color: white;

  padding: 30px 15px;
  @include tablet-portrait-up {
    padding: 120px 0;
  }

  h2,
  .h2 {
    font-size: 22px;
    font-weight: 800;

    @include tablet-portrait-up {
      font-size: 30px;
    }
    .yellow {
      color: $yellow;
    }
  }
  .btn.big {
    margin-top: 40px;
    color: white;
    font-size: 24px;
    background: $blue-all-in;
    font-weight: 800;
    padding: 15px 30px;

    @include tablet-portrait-up {
      padding: 17px 70px;
    }
    &:hover {
      background: lighten($blue-all-in, 10%);
    }
  }
}
