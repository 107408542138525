// Modal

.Modal {
  //bottom: 40px;
  background-color: $white;
  border-radius: 20px;
  box-shadow: $box-shadow;
  padding: 30px;

  @include vert-800-up {
    padding: 50px;
  }

  h3:not(.h4), .h3 {
    margin-bottom: 24px;
    line-height: 1;
  }

  .btn {
    //  @extend .btn.blue-all-in;
  }

  &.new-class {
    text-align: center;
    @extend .col-6;
    max-width: 630px;
    form {
      max-width: 310px;
      margin: 40px auto 24px;
      label,
      .help-text {
        text-align: left;
      }
    }
    button {
      margin-top: 24px;
    }

    .close,
    .cancel,
    .blue-all-in {
      margin-top: 0;
    }

    p.h5 {
      line-height: revert;
    }
  }

  &.large {
    max-width: 80vw;
  }

  &.image-modal {
    max-width: 50vw;
  }

  .radio-options {
    display: flex;
    justify-content: space-around;
    margin: 20px auto;

    .option {
      @extend h5;
      display: flex;
      align-items: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      padding: 10px;
      padding-left: 46px;
      cursor: pointer;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 36px;
        width: 36px;
        background-color: $grey-feedbacker;
        border-radius: 50%;
        transition: $transition;
      }
      &:hover input ~ .checkmark {
        background-color: $grey-loveheart;
      }
      input:checked ~ .checkmark {
        background-color: $blue-all-in;
      }

      input:checked ~ .checkmark:after {
        display: block;
      }
    }
  }

  textarea {
    padding: 20px;
    background-color: $grey-feedbacker;
    border-radius: 8px;
    border: none;
    width: 100%;
    min-height: 130px;
    color: $grey-space-cadet;
    font-size: 18px;
  }
}

.Overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($almost-black, 80%);
}
.mask {
  z-index: 999999;
}
