// Modal
.ReactModal__Overlay {
  z-index: 99;
}
.Modal {
  position: relative;
  z-index: 9999;
  .close {
    width: 19px;
    height: 19px;
    top: 12px;
    right: 15px;
    position: absolute;
    cursor: pointer;
    border-radius: 10px;
    color: white;
    text-align: center;
    font-size: 26px;
    line-height: 19px;
    font-weight: 500;
    padding: 0;
    padding-left: 1px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    &:before,
    &:after {
      content: '';
      display: block;
      background-color: $grey-space-cadet;
      width: 4px;
      height: 20px;
      transform: rotate(45deg);
      position: absolute;
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  //person information for pick a team activity
  .person-info {
    text-align: left;
    text-transform: capitalize;
    font-size: 17px;
    ul {
      margin-left: 0;
      padding-left: 0;
      li {
        list-style: none;
      }
    }
    p {
      text-transform: none;
    }
  }
}
