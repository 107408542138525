// Join link

.join-link {
  position: absolute;
  left: 5vw;
  bottom: 2vh;
  padding: 6px 10px;
  border-radius: 8px;
  background: white;
  color: $grey-space-cadet;
  box-shadow: $box-shadow;
  z-index: 10;
}
