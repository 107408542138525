// Slide here
.lesson-slide.activity-instruction-slide {
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: url(/images/slides/shapes/activity-fullscreen-off-right.svg) no-repeat right /
      contain;
    z-index: 1;
    pointer-events: none;
  }
  h4, .h4,
  h1, .h1 {
    color: $champagne-gold;
    max-width: 80vw;
    @include hd-up {
      max-width: 60vw;
    }
  }
  .copy {
    @extend h3;
    max-width: 60%;
  }
}
