// Video, responsive wrapper
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-video {
  aspect-ratio: 16 / 9;
  width: 100%;
  &--16-8 {
    aspect-ratio: 16 / 8;
  }
}
