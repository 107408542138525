//Globals
html {
  font-family: $font-family-base;
  font-size: 14px;
  line-height: 1.6;
}

h1,
.h1 {
  font-family: 'AesteticoInformal';
  font-weight: 900;
  font-size: 40px;

  @include pc-laptop-up {
    font-size: 60px;
  }
  @include hd-up {
    font-size: 72px;
  }
}

h1:focus-visible[tabindex='-1'] {
  outline: none;
}

h1.h2,
h1.h3,
h1.h4,
h1.h5,
h1.h6 {
  font-family: $font-family-base;
}

p.h1,
p.h2,
p.h3,
p.h4,
p.h5,
p.h6 {
  line-height: $headings-line-height;
}

h2,
.h2 {
  font-weight: 900;
  font-size: 32px;

  @include pc-laptop-up {
    font-size: 40px;
  }
  @include hd-up {
    font-size: 52px;
  }
}

h1,
h2 {
  &:not(.h3, .h4, .h5, .h6, .quote) {
    margin-bottom: 0.5em;
  }
}

h3,
.h3 {
  font-size: 36px;
  font-size: 2.5vw;
  font-size: clamp(24px, 2.5vw, 36px);
  font-weight: 700;
}

h4,
.h4 {
  font-size: 28px;
  font-size: 2vw;
  font-size: clamp(20px, 2vw, 28px);
  font-weight: 700;
}
h5,
.h5 {
  font-size: 18px;
  font-size: 1.25vw;
  font-size: clamp(18px, 1.25vw, 24px);
  font-weight: 700;
}

h6,
.h6 {
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: 700;
}
.small {
  font-size: 14px;
}

// body small
.type-hs {
  font-size: 18px;
  font-weight: 700;
}

// body small
.type-bs {
  font-size: 14px;
  font-weight: 400;
  color: $grey-independence;
}

// body small
.type-bl {
  font-size: 17px;
}

.type-h2d {
  @extend h2;
  font-weight: 900;
}
.type-h3 {
  @extend h3;
}
.type-h3d {
  @extend h3;
  font-weight: 900;
}

.type-ui {
  font-size: 16px;
  font-weight: 700;
}

.xb {
  font-weight: 900;
}

// body small bold
.type-bsb {
  @extend .type-bs;
  font-weight: 700;
}

.c-gi {
  color: $grey-independence;
}
.c-gl {
  color: $grey-loveheart;
}
.c-gm {
  color: $grey-manatee;
}
.c-cg {
  color: $champagne-gold;
}
.c-blue {
  color: $blue-all-in;
}

.c-red {
  color: $coral-red;
}
.c-orange {
  color: $orange-warning;
}
.c-green {
  color: $green-say-yes;
}

.needs-link {
  color: white;
  background: magenta;
  position: relative;
  &:after {
    position: absolute;
    top: 22px;
    border-radius: 2px;
    font-weight: 900;
    left: 0;
    color: white;
    background: $red-error;
    padding: 2px 8px;
  }
  &:hover:after {
    content: 'Needs a link';
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

a,
.a {
  cursor: pointer;
  transition: $transition;

  &.dark {
    color: $grey-space-cadet;
    &:hover {
      color: $blue-all-in;
    }
  }

  &.underlined {
    text-decoration: underline;
  }
}

.email-cta {
  margin: 0 !important;

  a {
    color: $white;
    &:hover {
      color: $grey-space-cadet;
    }
  }
}
