// Slide here
.lesson-slide.acronym-slide {
  .acronym {
    background-color: $grey-independence;
    border-radius: 35px;
    display: flex;
    font-size: 270px;
    color: white;
    font-weight: 900;
    letter-spacing: 0.3em;
    text-align: center;
    justify-content: center;
    padding: 3vh;

    &.expanded {
      font-size: 112px;
      display: block;
      letter-spacing: 0;
    }
  }
  .selected {
    color: $topic-media;
  }

  .sentences {
    p.h2 {
      margin-bottom: 0.5em;
    }
  }

  .list {
    list-style: none;
    padding: 20px 0;
    margin: 20px 0 0;

    li:not(.h3, .h4, .h5, .h6, .quote) {
      @extend h2;
      margin: 5px 0;
    }
  }

  .wrap {
    text-align: left;
    .title-bar h1 {
      background-color: $grey-independence;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      font-size: 70px;
      padding: 20px 50px;
      font-weight: 900;
      align-items: center;
      margin-bottom: 0;
      font-family: revert;
      line-height: revert;
      .word {
        color: $topic-media;

        &.medium {
          font-size: 44px;
        }
        .small {
          font-size: 40px;
        }
      }
      .small-acronym {
        letter-spacing: 0.2em;
        span {
          color: $topic-media;
        }
      }
    }
    .description {
      padding: 8vh 50px;
      h2, .h2 {
        margin-bottom: 3vh;
      }
    }
  }

  &.think {
    h1, .h1 {
      margin: 0;
    }
  }

  &.sure-details {
    .panel {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 15vh;
      margin-bottom: -10vh;

      &.panel-2 {
        margin-bottom: 0;
        padding-top: 10vh;
        h3, .h3 {
          margin: 0;
        }
      }
      .wrap {
        padding: 20px;
        position: relative;
      }
      .n {
        position: absolute;
        max-width: 300px;
        text-align: center;
        font-size: 18px;
        &:after {
          content: '';
          width: 26px;
          height: 217px;
          background: url('/images/icons/line-1.svg') no-repeat center / contain;
          position: absolute;
          display: block;
        }
      }
      .note-1 {
        top: -80px;
        left: 0px;
        &:after {
          background-image: url('/images/icons/line-3.svg');
          width: 26px;
          height: 164px;
          left: 50%;
          bottom: -150px;
        }
      }
      .note-2 {
        top: -60px;
        right: 0;
        width: 200px;
        &:after {
          background-image: url('/images/icons/line-2.svg');
          width: 26px;
          height: 164px;
          left: -10%;
          bottom: -150px;
        }
      }
      .note-3 {
        top: -100px;
        left: -40px;
        &:after {
          background-image: url('/images/icons/line-3.svg');
          width: 26px;
          height: 164px;
          bottom: -145px;
          left: 40%;
        }
      }
      .note-4 {
        top: -70px;
        right: 0;
        width: 190px;
        &:after {
          background-image: url('/images/icons/line-4.svg');
          width: 26px;
          height: 84px;
          left: 50%;
          bottom: -70;
        }
      }
      .note-5 {
        top: -50px;
        right: 10%;
        width: 300px;
        &:after {
          background-image: url('/images/icons/line-2.svg');
          width: 26px;
          height: 164px;
          left: 10%;
          bottom: -170px;
        }
      }
      .note-6 {
        top: -30px;
        left: 20%;
        &:after {
          background-image: url('/images/icons/line-5.svg');
          width: 29px;
          height: 370px;
          bottom: -380px;
          left: 40%;
        }
      }
      .note-7 {
        max-width: 400px;
      }
      .note-8 {
        max-width: 650px;
        line-height: 1.4;
      }
    }
    $br: 12px;

    .profile-header {
      border-radius: $br;
    }
    .post-top {
      border-radius: $br $br 0 0;
    }
    .post-bottom {
      border-radius: 0 0 $br $br;
    }
  }
}
