// Slide here
.lesson-slide.activity-underway-slide {
  h1:not(.h2,.h3,.h4,.h5,.h6,.quote) {
    font-size: 160px;
    margin-bottom: 10px;
  }
  .go {
    color: $champagne-gold;
    @media (max-height: 870px) {
      font-size: 10vh;
    }
  }
  .expired {
    color: $coral-red;
  }
  .copy {
    @extend h3;
    max-width: 60%;
    margin: auto;
  }

  &.activity-underway-notes-slide {
    justify-content: center;
    flex-direction: column;

    .subcopy {
      padding-right: 1em;
      font-size: 26px;
      font-weight: 900;
    }

    .row {
      width: 100%;
    }
  }
}
