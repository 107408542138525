@media print {
  header.header,
  footer.footer {
    display: none;
  }

  html {
    font-size: 12pt !important;
  }

  *,
  *:after {
    background: transparent !important;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;
    color: black !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    height: auto !important;
    line-height: 1.3 !important;
    margin: 0 !important;
    max-height: none !important;
    max-width: 100% !important;
    padding: 0 !important;
    position: relative !important;
    text-align: left !important;
    top: initial !important;
    vertical-align: top !important;
  }

  div,
  img,
  main {
    width: 100% !important;
  }

  table {
    margin-bottom: 1.5rem !important;
  }

  tr > th,
  tr > td {
    border-bottom: 1px solid !important;
    border-top: 1px solid !important;
    padding: 0.5rem 0 !important;
  }

  div.table,
  div.table div {
    width: revert !important;
  }

  div.table div.row {
    display: flex !important;
    margin-bottom: 0.5rem !important;
  }

  div {
    border: none !important;
    display: block !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700 !important;
    margin-bottom: 1.25rem !important;

    span {
      font: inherit !important;
    }
  }

  h1 {
    font-size: 3.052rem !important;
  }

  h2 {
    font-size: 2.441rem !important;
  }

  h3 {
    font-size: 1.953rem !important;
  }

  h4 {
    font-size: 1.563rem !important;
  }

  h5 {
    font-size: 1.25rem !important;
  }

  p,
  ul,
  ol,
  button,
  .button-set,
  header {
    margin-bottom: 1rem !important;

    span {
      font: inherit !important;
    }
  }

  ul {
    list-style-position: outside !important;
    list-style-type: disc !important;

    li {
      list-style: disc !important;
      margin-left: 1.5rem !important;
    }
  }

  a {
    text-decoration: underline !important;
  }

  a[href^='/']:after {
    content: ' (http://clickagainsthate.org.au' attr(href) ') ';
    display: inline-block !important;
    margin-bottom: 1rem !important;
  }

  img {
    height: auto;
    margin-bottom: 1rem !important;
    max-width: 75% !important;
    min-width: auto !important;
  }

  [aria-label] > * {
    display: none !important;
  }

  [aria-label]:after {
    content: attr(aria-label);
    display: inline-block !important;
  }

  @page {
    margin: 20mm;
    margin-right: 50mm;
  }

  .lesson-plan-page .plan {
    &.limited-print {
      .side-nav,
      .section:not(#lesson-plan) {
        display: none !important;
      }
    }
  }

  .student-screens,
  .slide > button,
  .slide > button > span {
    width: 100% !important;
  }

  .slide button span span {
    display: none !important;
  }

  .time,
  .teaching-notes {
    display: none !important;
  }

  .dropdown::after,
  .dropdown::before {
    content: none !important;
  }

  .lesson-step {
    page-break-after: always !important;
  }

  .marker-app {
    display: none !important;
  }
}
