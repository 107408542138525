// Slide here
.lesson-slide.reflection-slide {
  background-color: $grey-space-cadet;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 1.5s ease 1s 1 forwards;
    background: url(/images/slides/shapes/hanging-top-right-simple.svg) no-repeat right / contain;
    pointer-events: none;
  }

  h2:not(.h4), .h3:not(h1) {
    font-weight: 700;
    padding: 0 20px;
    margin: 40px 0 10px;

    &.c1 {
      color: $blue-all-in;
    }
    &.c2 {
      color: $green-say-yes;
    }
    &.c3 {
      color: $pink-you-and-me;
    }
  }
  p {
    font-size: 24px;
    padding: 0 20px;

    &.input-prompt {
      padding: 10px 0 0;
      margin-bottom: 0;
      font-size: 17px;
      color: $grey-independence;
    }
  }

  .supplementary-copy {
    font-size: 16px;
    padding: 0;
  }
}
