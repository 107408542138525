// Slide here
.lesson-slide.activity-hate-scenarios {
  .countdown-clock {
    color: white;
  }
  .scenario-container {
    position: relative;
    .copy {
      position: absolute;
      top: 0;
      left: 0;
      width: 38vw;
    }
    .scenario-image {
      top: 12vh;
      margin-left: 3vw;
      position: relative;
    }
  }
  .answer-btn {
    margin-top: 20px;
  }
  .answer-box {
    margin-top: 40px;
    box-shadow: $box-shadow;
    background-color: $grey-cultured-bone;
    border-radius: 20px;
    padding: 30px;
    color: $grey-space-cadet;
    h3, .h3 {
      margin: 10px 0;
      font-weight: 900;
    }
  }
  p.h4 {
    margin-bottom: 0.5rem;
  }
}
.Modal.activity-hate-scenarios-modal {
  text-align: left;
  .btn-strip {
    margin-top: 20px;
    button {
      margin: 5px 0;
      z-index: 10;
      display: block;
      width: 100%;
      padding: 10px 20px;
    }
  }
}
