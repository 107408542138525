// Slide here
.lesson-slide.activity-pick-stereotype {
  // background-color: $cream-seashell;
  // color: $grey-space-cadet;

  text-align: center;

  .options {
    width: 100%;
    justify-content: center;
    margin-top: 5vh;
    max-height: 80vh;
    display: flex;

    .option {
      margin: 15px;
      padding: 5px;
      border: 3px solid transparent;
      cursor: pointer;
      border-radius: 12px;
      img {
        border-radius: 8px;
      }

      &.selected {
        border-color: $blue-all-in;
      }
    }
  }

  .countdown-clock {
    color: white;
  }

  &.results {
    .options .option {
      cursor: pointer;
      position: relative;

      &.correct {
        .score-label {
          color: white;
          background-color: $green-bee-gee;
        }
      }
      &.incorrect {
        .score-label {
          color: white;
          background-color: $coral-red;
        }
      }

      p {
        margin-bottom: 0;
      }
    }
    .score-label {
      position: absolute;
      bottom: -2px;
      border-radius: 0 0 8px 8px;
      left: 5px;
      width: calc(100% - 10px);
      @extend h5;
      padding: 20px 0;
      background-color: white;
      color: $grey-space-cadet;
      font-weight: 900;
    }

    .scored {
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 20px;
    }
  }
}
