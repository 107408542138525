// Sections can be added to any page in any order
section.one-col-offset {
  padding: 120px 0;

  h3, .h3 {
    font-weight: 900;
    margin-top: 0;
  }
  .h4 {
    font-weight: 700;
  }
  p {
    margin-top: 20px;
    font-size: 22px;
    @extend .type-bl;
  }
  .copy {
    @extend .col-7;
  }

  &.right {
    .row {
      justify-content: flex-end;
    }
  }
  &.first {
    padding-top: 150px;
  }
  &.last {
    padding-bottom: 150px;
  }
}
