// Slide here
.activity-match-scenario {
  background: $cream-seashell;
  color: $grey-space-cadet;
  z-index: 99;
  position: relative;
  width: 100%;

  > .row {
    height: 100%;
    width: 100%;
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  h2, .h2 {
    margin-bottom: 5vh;
  }
  .scenario-previews {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    .col {
      display: flex;
    }
  }
  .scenario-preview {
    background-color: white;
    margin: 0 0.5vw;
    border-radius: 12px;
    box-shadow: $box-shadow;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    position: relative;
    &:hover {
      box-shadow: $box-shadow-hover;
    }

    .fake-img {
      background-color: $coral-red;
      height: 200px;
      width: 200px;
      margin-bottom: 20px;
      border-radius: 8px;
      padding: 30px;
      text-align: center;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      h3, .h3 {
        font-weight: 900;
        margin-bottom: 0.5rem;
      }
    }

    img {
      margin-bottom: 2vh;
      border-radius: 8px;
      background-color: $cream-seashell;
    }
    &.answered img,
    &.answered .img {
      background-color: $green-say-yes;
    }
    .chosen-answer {
      color: $grey-space-cadet;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
    }
    .btn {
      //margin-top: 3vh;
      &.change {
        font-size: 14px;
        padding: 5px 10px;
        margin-top: 1vh;
        position: absolute;
        top: 15px;
        right: 25px;
      }
    }
  }
}
.match-scenario-modal {
  img {
    background-color: $cream-seashell;
    max-height: 80vh;
    object-fit: contain;
  }
  .video {
    max-height: 80vh;
    max-width: 100%;
  }
  .copy {
    padding: 0 20px 20px;
    font-size: 18px;

    &.large {
      font-size: 22px;

      p {
        margin: 20px 0;
      }
    }
  }
  .img-wrap {
    display: flex;
    justify-content: center;
  }

  .btn-pair .btn {
    padding: 10px 30px;
  }

  .fieldset {
    padding: 0 20px;
  }
  .cause-options {
    .h5 {
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }

    > button {
      padding: 2px 10px;
      background-color: $coral-red;
      border-radius: 15px;
      margin: 0 0 0 10px;
    }

    .clear {
      line-height: 1.2;
    }

    .radio-option {
      margin: 5px 0;
      font-size: 15px;
      display: flex;
      cursor: pointer;
      padding: 0;
      background-color: unset;
      color: #2c2c54;
      font-weight: 400;

      &.selected {
        color: $blue-all-in;

        i:before {
          position: absolute;
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 8px;
          background-color: $blue-all-in;
          top: 2px;
          left: 2px;
        }
      }

      i {
        position: relative;
        display: inline-block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 2px solid $grey-space-cadet;
        margin-right: 5px;
      }
    }
    .radio-option span {
    }
  }
  .fake-label {
    margin: 10px 0;
  }

  textarea {
    min-height: 100px;
  }

  .btn-group {
    margin-top: 15px;
    @include vert-700-up {
      margin-top: 30px;
    }
  }
}
