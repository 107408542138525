// Cards

.card-list {
  margin: 32px 0;
  padding: 0;
}
.card {
  background: white;
  padding: 32px;
  border: 1px solid $grey-feedbacker;
  border-radius: 8px;
  margin-bottom: 24px;

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button:first-child {
      margin-right: 16px;
    }
  }

  &.summary {
    position: sticky;
    top: 32px;
    text-align: center;
    background: white;
    padding: 32px;
    border: 1px solid $grey-feedbacker;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 32px 0 24px;

    .title {
      color: $grey-manatee;
      padding-bottom: 20px;
      border-bottom: 1px solid $grey-feedbacker;
      margin-bottom: 0;
    }
    .body {
      padding: 32px 32px 0;
    }
    .total-time {
      line-height: 1.4;
      margin-bottom: 16px;
    }
    button {
      margin-bottom: 16px;
    }
    .save {
      padding: 0;
      margin: 0;
      margin-top: 16px;
      display: inline-block;
      background-color: transparent;
      font-weight: 400;
      font-size: 1rem;
    }
    ul {
      margin: 32px 0;
      padding: 0;
      li {
      }
    }

    .module {
      @include topic-colours;
      list-style: none;
      text-align: left;
      padding: 15px 45px 15px 15px;
      font-weight: 700;
      border-radius: 8px;
      margin-bottom: 8px;
      position: relative;

      .time {
        font-weight: normal;
      }
      .close {
        background-color: $grey-space-cadet;
        width: 19px;
        height: 19px;
        top: 12px;
        right: 12px;
        position: absolute;
        cursor: pointer;
        border-radius: 10px;
        color: $white;
        text-align: center;
        font-size: 26px;
        line-height: 19px;
        font-weight: 500;
        padding: 0;
      }
    }
  }

  &.alert {
    border: 1px solid $coral-red;
    padding: 25px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &.warning {
    background-color: $faded-orange-warning;
    border: 1px solid $orange-warning;
    padding: 25px;

    p:last-child {
      margin-bottom: 0;
    }
  }
}
