// Team Badge
.badge-trustworthy {
  width: 30vh;
  height: 30vh;
  border-radius: 50%;
  background: url('/images/icons/trustworthy.svg') no-repeat center / cover;

  &.untrustworthy {
    background: url('/images/icons/untrustworthy.svg') no-repeat center / cover;
  }
}
