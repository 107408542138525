// Slide here
.lesson-slide.join-slide {
  .url-wrap {
    position: relative;
    width: 40vw;
  }
  .url-field {
    @extend .h2;
    font-weight: 900;
    color: $yellow;
    padding: 20px 70px;
    border-radius: 100px;
    background: $grey-independence;
    margin: 30px 0 70px;
    position: relative;
    text-align: center;
    user-select: text;
    display: inline-block;
  }
  .tip {
    position: absolute;
    @extend .h4;
    color: $green-say-yes;
    &:after {
      position: absolute;
      content: '';
      display: block;
      background: url('/images/slides/join/url-arrow.svg') no-repeat center;
      width: 110px;
      height: 101px;
    }
  }
  .url-tip {
    right: -227px;
    top: -80px;
  }
  .start-tip {
    right: 5vw;
    bottom: 28vh;
    width: 300px;
    &:after {
      position: absolute;
      content: '';
      display: block;
      background: url('/images/slides/join/url-arrow.svg') no-repeat center;
      width: 110px;
      height: 101px;
      transform: rotate(125deg);
      top: 110px;
      left: 100px;
    }
  }

  h3, .h3 {
    transition: $transition;
    &.disabled {
      color: $grey-independence;
    }
    &.active {
      color: $white;
    }
  }

  .student-counter {
    font-size: 160px;
    line-height: 1;
    font-weight: 900;
    color: $yellow;
    transition: $transition;
    margin: 40px 0 -20vh;
    &.disabled {
      color: $grey-independence;
    }
  }

  .student-list {
    display: flex;
    flex-direction: column;
    height: 35vh;
    overflow: hidden;
    margin-top: 40px;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 30vh;
      position: absolute;
      bottom: 0;
      background: linear-gradient(0, $grey-blackout, transparent);
    }
    .avatar-preview {
      width: 100px;
      height: 100px;
      margin-top: -18px;
      img {
        height: 100px;
      }
    }
    li span {
      font-size: 32px;
    }
  }
}
