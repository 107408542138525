// Sections can be added to any page in any order
section.video-with-intro {
  padding: 30px 15px;
  text-align: center;
  background: url('/images/website/video-leaves.svg') no-repeat center;

  @include tablet-portrait-up {
    padding: 120px 0;
  }

  h3, .h3,
  h5, .h5 {
    font-weight: 900;
  }
  h3, .h3 {
    margin-top: 24px;
  }
  .embed-container {
    margin-top: 80px;
    border-radius: 18px;
    box-shadow: $box-shadow;
    box-shadow: 0 4px 54px rgba(#2d2d32, 30%);
  }
}
