// Footer

.footer {
  padding: 20px;
  background-color: $grey-cultured-bone;

  @include tablet-portrait-up {
    padding: 80px 0 60px;
  }
  .logo {
    max-width: 110px;
    max-height: 80px;
    margin-right: 40px;
    margin-bottom: 30px;
    transition: $transition;

    &:hover {
      opacity: $hover-opacity;
    }
  }
  .byline {
    font-size: 16px;
    max-width: 220px;
    line-height: 1.5;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0 0 20px;
    }

    a {
      text-decoration: none;
      font-weight: bold;
      font-size: 15px;
      color: $grey-space-cadet;
      transition: 0.3s ease-in-out;
      &:hover {
        color: $blue-all-in;
      }
    }
  }
  .disclaimer {
    text-align: right;
  }

  @include mobile-only {
    .row {
      display: block;
    }
    .col-4 {
      width: 100%;
    }
    .col-2 {
      width: 100%;
    }
  }
}
