// Icons

i.trash {
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('/images/icons/trash.svg') no-repeat center / contain;
  margin: 2px 0 -2px;
}

i.screen {
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('/images/icons/student-screen.svg') no-repeat center / contain;
  margin: 2px 5px -2px 0;
}
i.teaching-notes {
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('/images/icons/teaching-notes.svg') no-repeat center / contain;
  margin: 3px 5px -3px 0;
}

i.arr-r {
  content: '';
  width: 30px;
  height: 22px;
  display: inline-block;
  background: url('/images/icons/white-arrow-right.svg') no-repeat center / contain;
  margin: 3px -10px -3px 5px;
}

i.trustworthy {
  background: url('/images/icons/trustworthy-invert.svg') no-repeat center / cover;
  width: 35px;
  height: 35px;
  display: inline-block;
  margin: -9px 0 -12px -13px;
}

i.untrustworthy {
  background: url('/images/icons/untrustworthy-invert.svg') no-repeat center / cover;
  width: 35px;
  height: 35px;
  display: inline-block;
  margin: -9px 0 -12px -13px;
}

i.time {
  background: url('/images/icons/timer.svg') no-repeat center / cover;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: -10px 5px -6px 0;
}

i.verified {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url('/images/icons/verified.svg') no-repeat center / contain;
  margin: 0px 0px -4px 0px;
}

i.email {
  content: '';
  width: 28px;
  height: 28px;
  display: inline-block;
  background: url('/images/icons/email.svg') no-repeat center / contain;
  margin: 0px 20px -4px 0px;
}

.email-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: $grey-space-cadet;
  box-shadow: $box-shadow;
  transition: 0.3s ease-in-out;
  user-select: none;
  z-index: 10;
  padding: 0;
  margin-right: 24px;
  line-height: 38px;
  vertical-align: bottom;

  i {
    background: url('/images/icons/email.svg') no-repeat center / contain;
    pointer-events: none;
    display: block;
    width: 30px;
    height: 30px;
  }
}
