// Slide here
// Website Preview, like in 7/8 MM1
.lesson-slide.website-preview-slide {
  color: $grey-space-cadet;
  background: $cream-seashell;

  flex-direction: column;
  justify-content: flex-end;

  p.h4 {
    margin-bottom: 0.5rem;
  }

  .preview-screen {
    position: relative;
    overflow: hidden;
    height: 70vh;
    max-width: 1200px;
    width: 100%;
    margin: 5vh auto -5vh;

    img {
      position: absolute;
    }

    .marker {
      position: absolute;
      background-color: $green-bee-gee;
      border: 2px solid white;
      padding: 10px 15px;
      border-radius: 30px;
      color: white;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1;
      font-weight: 900;

      &:before {
        position: absolute;
        content: '';
        background-color: $green-bee-gee;
        border: 2px solid white;
        border-top-width: 0;
        border-left-width: 0;
        width: 16px;
        height: 16px;
        left: 50%;
        bottom: -10px;
        transform: rotate(45deg);
        margin-left: -8px;
      }
      &.under:before {
        transform: rotate(-135deg);
        bottom: auto;
        top: -10px;
      }

      &.wrong {
        background-color: $coral-red;
        &:before {
          background-color: $coral-red;
        }
        i {
          background: url('/images/icons/untrustworthy-invert.svg') no-repeat center / cover;
        }
      }

      i {
        background: url('/images/icons/trustworthy-invert.svg') no-repeat center / cover;
        width: 35px;
        height: 35px;
        display: inline-block;
        margin: -9px 0 -12px -13px;
      }

      &.m1 {
        left: 482px;
        top: 33px;
      }
      &.m2 {
        left: 20%;
        top: 35%;
      }
      &.m3 {
        left: 69px;
        top: 418px;
      }
      &.m4 {
        left: 900px;
        top: 360px;
      }
      &.m5 {
        left: 460px;
        top: 1430px;
      }
      &.m6 {
        left: 460px;
        top: 2010px;
      }
      &.m7 {
        left: 250px;
        top: 1710px;
      }
      &.m8 {
        left: 260px;
        top: 2190px;
      }
      &.m9 {
        left: 450px;
        top: 2170px;
      }
    }
  }

  .bottom-toolbar {
    position: absolute;
    background-color: white;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 10;
    box-shadow: $box-shadow;
    height: 15vh;
    align-items: center;

    .btn {
      margin: 0 10px;
      align-items: center;
      display: flex;

      .i {
        margin-right: 10px;
        box-shadow: $box-shadow;
        border-radius: 50%;
      }
    }
  }

  &.website-review-student {
    .bottom-toolbar ~ .preview-screen {
      height: 60vh;
      margin-bottom: 10vh;
    }
    .preview-screen {
      overflow-y: scroll;

      position: relative;
    }
  }
}
