// Slide here
.lesson-slide.student-blank-slide {
  background-color: $grey-blackout;
  h4, .h4 {
    color: $grey-independence;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 40px;
  }
}
