// Slide here
.lesson-slide.activity-social-media-features {
  &.results {
    h1 {
      font-family: AesteticoInformal;
    }
    .answers {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
    }
    .answer {
      margin-top: 5vh;
      width: 32%;
      border-radius: 20px;
      box-shadow: $box-shadow;
      background-color: $cream-seashell;
      color: $grey-blackout;
      padding: 20px;
      text-align: left;

      h4, .h4 {
        margin-top: 30px;
        font-weight: 700;
      }
      h3, .h3 {
        @extend .xb;
        margin: 30px 0;
      }
    }
  }
}
