// Slide here
.lesson-slide.activity-student-where-to-sit {
  background: $grey-feedbacker;
  color: $grey-space-cadet;
  .row {
    width: 100%;
  }

  p {
    @extend h4;
    color: $grey-manatee;
    margin: 20px 0 20px;
  }

  .illustration-panel {
    display: flex;
    position: relative;
    height: 50vh;
    width: 100%;
    background: $white;
    &.bus-1 {
      background: $white url('/images/slides/placeholder/activity-bus.jpg') no-repeat center /
        contain;
      padding-bottom: 20px;
    }

    .option {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      cursor: pointer;
      border: 5px solid $grey-feedbacker;
      transition: $transition;

      &.selected {
        border-color: $blue-all-in;
      }

      input {
        visibility: hidden;
      }

      position: absolute;

      &.person-1 {
        top: 29px;
        left: 151px;
      }

      &.person-2 {
        top: 123px;
        left: 231px;
      }
      &.person-3 {
        top: 21px;
        right: 344px;
      }
    }
  }
}
