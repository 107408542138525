// Slide here
.lesson-slide.reflection-slide {
  background-color: $cream-seashell;
  color: $grey-space-cadet;

  textarea {
    min-height: 25vh;
    &:first-child {
      margin-top: 0;
    }
  }
}
