.student-list {
  padding: 0;

  position: relative;

  li {
    list-style: none;
    background: $grey-independence;
    padding: 13px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    margin: 0 auto 10px 0;

    span {
      @extend h4;
      font-weight: 900;
      padding: 0 20px;
      line-height: 1;
      margin: 0;
      text-transform: capitalize;
    }
  }

  .avatar-preview {
    width: 50px;
    height: 50px;
    margin-top: -10px;
    img {
      height: 50px;
      // border-radius: 50%;
    }
  }
}
