@use "sass:math";

// Variables
$variable-prefix: cah;
// colours

$test-green: green;

$blue-all-in: #5b8df4;
$blue-faves: #6b98f5;
$green-bee-gee: #34c2a0;
$green-say-yes: #35e1c0;
$blue-sky-crayola: #80ebff;
$coral-red: #ff7580;
$yellow: #fffd72;
$faded-yellow: #ffe074;
$champagne-gold: #fae7ac;
$pink-you-and-me: #ffbdff;
$pink-miami: #fddff0;
$cream-seashell: #fcf6ee;

$topic-stereotypes: #ffe074;
$topic-media: #ffcfbb;
$topic-discrimination: #c2ede3;
$topic-online: #c6e7ff;

$topic-1: $topic-stereotypes;
$topic-2: $topic-media;
$topic-3: $topic-discrimination;
$topic-4: $topic-online;

$red-error: #ff006b;
$green-success: #05ecb4;
$orange-warning: #ff9900;
$faded-orange-warning: #fff3c2;

$almost-black: #0a0a1c;
$grey-space-cadet: #2c2c54;
$grey-blackout: #333344;
$grey-independence: #5a5a6e;
$grey-manatee: #9494a8;
$grey-loveheart: #d5d5e1;
$grey-feedbacker: #eff1f8;
$grey-cultured-bone: #f7f8fc;
$white: white;
$black: black;

// measurements

$font-weight-base: 400;
$line-height-base: 1.4;

$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$font-family-base: 'Aestetico', sans-serif;

$enable-smooth-scroll: true !default;
$enable-grid-classes: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: math.div($spacer, 4),
  2: math.div($spacer, 2),
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$grid-columns: 12;
//$column: 88px;
$gutter: 16px;
$grid-row-columns: 6 !default;
$gutters: $spacers !default;

// Container padding
$container-padding-x: math.div($gutter, 2) !default;

// $column-t: 69px;
// $column-m: 64px;

$br: 4px;
$br-card: 14px;
$br-large: 50px;

// effects

$drop-large-dark: 0 8px 26px rgba(#38385a, 15%);
$box-shadow: $drop-large-dark;
$box-shadow-hover: 0 12px 30px rgba(#38385a, 35%);
$t-duration: 0.3s ease;
$transition: all $t-duration;
$hover-opacity: 0.7;

// breakpooints

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1280px,
) !default;
// scss-docs-end container-max-widths

// $max-width: 1280px;
// $max-width-t: 688px;
// $max-width-m: 315px;

@include _assert-ascending($container-max-widths, '$container-max-widths');

$aspect-ratios: (
  '1x1': 100%,
  '4x3': calc(3 / 4 * 100%),
  '16x9': calc(9 / 16 * 100%),
  '21x9': calc(9 / 21 * 100%),
) !default;

// DEFAULTS

//color defaults:
$body-color: $grey-space-cadet;
$body-bg: $white;
$border-color: $grey-space-cadet !default;
$text-muted: $grey-manatee !default;
$link-color: $blue-all-in !default;
$link-decoration: underline !default;
$link-shade-percentage: 20% !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: null !default;

//other defaults:
$body-text-align: left;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-family-base: var(--#{$variable-prefix}font-sans-serif) !default;
$font-family-code: var(--#{$variable-prefix}font-monospace) !default;
$border-width: 1px !default;
$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;

$small-font-size: 0.875em !default;
$mark-padding: 0.2em !default;
$mark-bg: #fcf8e3 !default;
$sub-sup-font-size: 0.75em !default;

$headings-margin-bottom: math.div($spacer, 2) !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$hr-margin-y: $spacer !default;
$hr-color: inherit !default;
$hr-height: $border-width !default;
$hr-opacity: 0.25 !default;

$dt-font-weight: $font-weight-bold !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$paragraph-margin-bottom: 1rem !default;

$code-font-size: $small-font-size !default;

// scss-docs-start table-variables
$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 0.5rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;

$table-cell-vertical-align: top !default;

$table-color: $body-color !default;
$table-bg: transparent !default;

$table-th-font-weight: null !default;

$table-striped-color: $table-color !default;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($black, $table-striped-bg-factor) !default;

$table-active-color: $table-color !default;
$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;

$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($black, $table-hover-bg-factor) !default;

$table-border-factor: 0.1 !default;
$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-striped-order: odd !default;

$table-group-separator-color: currentColor !default;

$table-caption-color: $text-muted !default;

$table-bg-scale: -80% !default;

@mixin mobile-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: 1269px) {
    @content;
  }
}

@mixin tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin tablet-portrait-only {
  @media (min-width: 600px) and (orientation: portrait) and (max-width: 800px) {
    @content;
  }
}
@mixin tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin pc-laptop-up {
  @media (min-width: 1270px) {
    @content;
  }
}

@mixin macbook-up {
  @media (min-width: 1400px) {
    @content;
  }
}
@mixin big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin vert-700-up {
  @media (min-height: 700px) {
    @content;
  }
}
@mixin hd-up {
  @media (min-height: 800px) and (min-width: 1600px) {
    @content;
  }
}

@mixin vert-800-up {
  @media (min-height: 800px) {
    @content;
  }
}

@mixin ipad-only {
  @media (min-width: 1000px) and (max-width: 1200px) and (min-height: 700px) and (max-height: 800px) {
    @content;
  }
}

//768x1024
//message to rotate device to landscape

//1024x768

//1366x768

//1440x900
