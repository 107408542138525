// Slide here
// Pick a team activity, like in 7/8 S&P1
.lesson-slide.ideal-team {
  .people-cards {
    display: flex;
    margin-top: 50px;
  }

  .big-card {
    background-color: white;
    border-radius: 40px;
    box-shadow: $box-shadow;
    padding: 30px;
    width: calc(25% + 10px);
    margin: 0 -5px;

    &.card-1 {
      transform: rotate(1deg);
    }
    &.card-2 {
      transform: rotate(-3deg);
    }
    &.card-3 {
      transform: rotate(3deg);
    }
    &.card-4 {
      transform: rotate(-4deg);
    }

    .image {
      background-color: $grey-feedbacker;
      border-radius: 30px;
      text-align: center;
      min-height: 248px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 90px;
      color: $grey-manatee;
      font-weight: 900;
      img {
        border-radius: 30px;
        display: block;
      }
    }
    .fake-text {
      background-color: $grey-feedbacker;
      border-radius: 10px;
      height: 20px;
      margin: 20px;
    }
    .role {
      background: $grey-space-cadet;
      color: white;
      border-radius: 30px;
      font-weight: 600;
      text-align: center;
      padding: 15px;
      font-size: 1vw;
      margin-bottom: 0;
    }
    .text {
      color: $grey-space-cadet;
      text-align: center;
      margin: 20px;
      font-size: 1.5vw;
    }
  }
}
