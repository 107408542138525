// Sections can be added to any page in any order
section.two-col-offset {
  padding: 30px 0;

  @include tablet-portrait-up {
    padding: 120px 0;
  }

  &.red {
    color: white;
    background: $coral-red;
  }

  h3, .h3 {
    font-weight: 900;
    margin-top: 24px;
  }
  p {
    margin-top: 20px;
    font-size: 20px;
    @extend .type-bl;
  }
  .copy {
    @extend .col-7;
  }

  .row {
    align-items: center;
  }
}
