// Sections can be added to any page in any order
section.four-col {
  padding: 30px 0;

  @include tablet-portrait-up {
    padding: 120px 0;
  }

  &.red {
    color: white;
    background: $coral-red;
  }

  h3, .h3 {
    font-weight: 900;
    margin-top: 24px;
  }
  .h4 {
    font-weight: 700;
    margin-top: 0;
  }
  p {
    margin-top: 20px;
    font-size: 20px;
    @extend .type-bl;
  }
  .copy {
    @extend .col-7;
  }

  .row {
    align-items: center;
  }

  .benefit {
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    // flex-direction: column;
    margin-top: 40px;
    img {
      margin-bottom: 20px;
      height: 130px;
    }
  }

  .row {
    align-items: flex-start;
  }
}
