// Slide here
.lesson-slide.activity-observations-slide {
  background-color: $cream-seashell;
  color: $grey-space-cadet;

  .col-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  textarea {
    //min-height: 25vh;
    margin: 10px 0;
  }
  img {
    max-height: 85vh;
    align-self: center;
  }
}
