// Sections can be added to any page in any order
section.account-panel {
  font-size: 20px;

  h3, .h3 {
    font-weight: 900;
    margin: 1em 0 0.5em;
  }
  .h4 {
    font-weight: 700;
    margin: 0 0 0.25em;
  }
  p {
    margin-top: 20px;
  }

  dt {
    margin-top: 15px;
  }

  dd {
    font-size: 17px;
  }

  p,
  ul {
    margin-bottom: 1em;
  }
  li {
    margin-bottom: 0.3em;
  }
  .row {
    justify-content: center;
  }
  .copy {
    @extend .col-7;
    margin: 0 auto;
    padding: 20px 0;
    //text-align: center;
    border-bottom: 1px solid $grey-loveheart;
  }
  &.first {
    padding-top: 150px;
  }
  &.last {
    padding-bottom: 150px;

    .copy {
      border-bottom-color: transparent;
    }
  }

  .input-btn-pair {
    input {
      border-radius: 4px;
      padding: 8px 18px;
      border: 1px solid $grey-space-cadet;
      background: white;
    }
    .btn {
      margin-left: 10px;
    }
  }
}
.single-panel {
  min-height: 60vh;
  display: flex;
  align-items: center;
}
