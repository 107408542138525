.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 38px;
  height: 38px;
}

.loadingSmall {
  width: 160px;
  height: 32px;
}
