// Stereotypes 7/8 Module 1: Grid Head Slide here
.lesson-slide.grid-head-slide {
  background-color: $cream-seashell;
  color: $grey-space-cadet;
  h1 {
    font-family: AesteticoInformal;
    margin-bottom: 0.5em;
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .card {
    width: 18%;
    margin: 0.5%;
    background: $white;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;

    img {
      max-width: 77%;
      margin: auto;
      display: block;
    }
    p.h5, .h3 {
      margin-top: auto;
      margin-bottom: 10px;
    }
  }
}

// Stereotypes 7/8 Module 1: Bus Stop Slide here
.lesson-slide.bus-stop-slide {
  background-color: $cream-seashell;
  color: $grey-space-cadet;

  .row {
    position: relative;
    margin-top: 140px;
    .arrow {
      position: absolute;
      background: url('/images/slides/ui/arrow-down-blue.svg') no-repeat center;
      width: 133px;
      height: 127px;
      left: 5%;
      top: -140px;
    }
  }
  .col-3,
  .col-5 {
    display: flex;
  }
  .card {
    flex: 1;
    border-radius: 24px;
    display: flex;
    flex-grow: 1;
    text-align: left;
    background-color: $grey-loveheart;
    span {
      @extend .h4;
      color: $grey-manatee;
      line-height: 1.2;
    }
  }
  &.step-2,
  &.step-3,
  &.step-4 {
    .arrow {
      background: url('/images/slides/ui/arrow-right-blue.svg') no-repeat center;
      width: 146px;
      height: 52px;
      top: -70px;
      left: 20%;
    }
  }
  .col-3:first-child .card {
    background-color: $blue-sky-crayola;
    span {
      color: $grey-space-cadet;
    }
  }
  &.step-2 {
    .col-3:nth-child(2) .card {
      background-color: $blue-sky-crayola;
      span {
        color: $grey-space-cadet;
      }
    }
  }
  &.step-3 {
    .arrow {
      left: 45%;
    }
    .col-3:nth-child(2) .card,
    .col-3:nth-child(3) .card {
      background-color: $blue-sky-crayola;
      span {
        color: $grey-space-cadet;
      }
    }
  }
  &.step-4 {
    .arrow {
      left: 70%;
    }
    .col-3:nth-child(2) .card,
    .col-3:nth-child(3) .card,
    .col-3:nth-child(4) .card {
      background-color: $blue-sky-crayola;
      span {
        color: $grey-space-cadet;
      }
    }
  }

  //bus stop 2
  .col-5:first-child .card {
    background-color: $blue-all-in;
    span {
      color: white;
    }
  }
  .col-5:last-child .card {
    background-color: transparent;
  }
  &.step-2 {
    .col-5:last-child .card {
      background-color: $blue-all-in;
      span {
        color: white;
        span {
          color: $yellow;
        }
      }
    }
  }
}
