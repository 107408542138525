// Slide here
.lesson-slide.quote-slide {
  background: $cream-seashell;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  blockquote:not(.h3, .h4, .h5, .h6, .quote) {
    background: $blue-all-in;
    @extend h2;
    font-weight: 900;
    padding: 3vw 6vw;
    margin: 0 50px 0 150px;
    margin-bottom: 0;
    border-radius: 200px 200px 200px 0;
    position: relative;
    &:before {
      content: '';
      width: 100px;
      height: 100px;
      background-color: $blue-all-in;
      position: absolute;
      bottom: 0;
      left: -100px;
    }
    &:after {
      content: '';
      width: 200px;
      height: 200px;
      background-color: $cream-seashell;
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      left: -200px;
    }
  }
  cite {
    @extend h3;
    font-weight: 900;
    color: $blue-all-in;
    font-style: normal;
    margin-top: 2vh;
    margin-left: 50px;
  }
}
