// Header

.class-list {
  margin: 0;
  padding: 100px 0 60px;
  li {
    list-style: none;
    background: white;
    border-radius: $br-card;
    box-shadow: $box-shadow;
    padding: 32px 36px;
    margin-bottom: 24px;

    h2, .h2 {
      @extend h4;
      font-weight: 900;
      margin: 0 15px 0 0;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .right a {
    margin-left: 10px;
  }
  .modules-completed {
    display: flex;
    flex-wrap: wrap;
    margin: 24px -7px 0;

    .module {
      margin: 7px;

      width: calc(25% - 14px);
      //font-weight: 700;
      font-size: 14px;
      padding: 15px 35px 15px 15px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      @include topic-colours();

      .title {
        padding-right: 10px;
      }

      &:not(.completed) {
        background-color: $grey-feedbacker;
        .title {
          color: $grey-manatee;
        }
        path {
          fill: none;
        }
      }
      &.completed {
        .assessment {
          background-color: white;
          cursor: pointer;
          transition: $transition;
          &:hover {
            background-color: $grey-feedbacker;
          }
        }
      }

      .assessment {
        width: 35px;
        border-radius: 0 6px 6px 0;
        position: absolute;
        right: 2px;
        top: 2px;
        bottom: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: 0;
        padding: 0;
        svg {
          max-width: 19px;
          max-height: 19px;
        }
      }

      // &:nth-child(4n) {
      //     margin-left: 0;
      // }
      // &:nth-child(n+4) {
      //     margin-left: 0;
      // }
    }
  }
}

.prepare-header {
  padding-top: 80px;
  margin-bottom: 24px;
  h3, .h3 {
    margin-bottom: 30px;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.no-classes {
  @extend .col-9;
  text-align: center;
  img {
    display: block;
    margin: 20px 0;
  }
}
