// Slide here
.lesson-slide.transition-slide {
  .animated-wrap {
    right: 0;
    left: 0;
  }
  .skew:nth-child(1) .a-scroll {
    right: 0%;
  }
  .skew:nth-child(2) .a-scroll {
    right: 25%;
  }
  .skew:nth-child(3) .a-scroll {
    right: 50%;
  }
  .skew:nth-child(4) .a-scroll {
    right: 75%;
  }
  .skew:nth-child(5) .a-scroll {
    right: 100%;
  }

  .animated-wrap {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  .skew {
    transform: rotate(-30deg);
  }
  .a-scroll {
    position: absolute;
    top: -200px;
    // transform: rotate(-30deg);
    animation: scroll 20s linear 0s infinite alternate;
    //animation: name duration timing-function delay iteration-count direction fill-mode;
    z-index: 1;
  }
  .skew:nth-child(odd) .a-scroll {
    animation: scroll 20s linear 0s infinite alternate-reverse;
  }
  span {
    min-width: 290px;
    min-height: 40px;
    display: block;
    color: white;
    margin: 5px;
    font-weight: 900;
    font-size: 100px;
    line-height: 1;

    &:nth-child(5n + 1) {
      color: $green-say-yes;
    }
    &:nth-child(5n + 2) {
      color: $yellow;
    }
    &:nth-child(5n + 3) {
      color: $pink-you-and-me;
    }
    &:nth-child(5n + 4) {
      color: $blue-sky-crayola;
    }
    &:nth-child(5n + 5) {
      color: $white;
    }
  }
}
@keyframes scroll {
  100% {
    top: -800px;
    //transform: translate(-100px,-50px)
  } /* top is the number of spans (in this case 8) multiplied by span height (45px as described above)*/
}
