// Slide here
.lesson-slide.digital-footprint {
  .score {
    font-size: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vh;
    height: 20vh;
    border-radius: 50%;
    color: white;
    background-color: $green-say-yes;
    font-weight: 900;
    margin: 5vh auto;
    &.medium {
      background-color: $faded-yellow;
      color: $grey-space-cadet;
    }
    &.high {
      background-color: $coral-red;
    }
  }
}
