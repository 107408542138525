// Vendor
@import 'vendor/rfs';

@import 'mixins/grid';
@import 'mixins/breakpoints';
@import 'mixins/container';

%ui-navigation {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
}

@mixin topic-colours {
  &.topic-1 {
    border-color: $topic-1;
    background: $topic-1;
  }

  &.topic-2 {
    border-color: $topic-2;
    background: $topic-2;
  }
  &.topic-3 {
    border-color: $topic-3;
    background: $topic-3;
  }
  &.topic-4 {
    border-color: $topic-4;
    background: $topic-4;
  }
}
