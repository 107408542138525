// Header Navigation

.header {
  .nav {
    display: flex;
    margin: 0;
    list-style: none;
    align-items: center;
    // background: white;
    // padding: 15px 30px;
    // border-radius: 30px;

    li {
      margin-right: 36px;
      &:last-child {
        margin-right: 0;
      }
    }
    a, button {
      @extend %ui-navigation;
      text-decoration: none;
      color: $grey-space-cadet;
      transition: $transition;
      &:not(.round) {
        background-color: transparent;
        padding: 0;
      }
      &.btn {
        color: white;
      }
      &:not(.btn):hover {
        opacity: $hover-opacity;
      }
    }
  }
}
