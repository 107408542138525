// Slide here
.lesson-slide.online-hate-strategy-slide {
  background: $cream-seashell;
  color: $blue-all-in;

  .step {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .half {
      width: 50%;
    }
    h1:not(.h2) {
      font-size: 4.4vw;
    }
    img {
      max-height: 85vh;
    }
  }
  .list {
    margin-top: 5vh;
    padding-left: 4em;
  }
  li {
    @extend .h2;
    @extend .xb;
    color: $blue-all-in;
    margin-bottom: 5px;
  }
  .step-1 {
    img {
      position: absolute;
      bottom: -5vh;
      right: 10vw;
    }
  }
  .step-2 {
    img {
      position: absolute;
      top: -5vh;
      right: 10vw;
    }
  }
  .step-3 {
    img {
      position: absolute;
      top: -5vh;
      right: 10vw;
    }
  }
  .step-4 {
    img {
      position: absolute;
      bottom: -5vh;
      right: 10vw;
    }
  }
  .step-5 {
    img {
      position: absolute;
      bottom: -5vh;
      right: 10vw;
    }
  }

  &.recap-list {
    .annotation {
      display: none;
    }
    &.annotated {
      li {
        color: $grey-loveheart;
        &:nth-child(5) {
          color: $blue-all-in;
          position: relative;
        }
      }
      .annotation {
        display: block;
        position: absolute;
        color: white;
        background: $blue-all-in;
        border-radius: 45px;
        padding: 10px 25px;
        font-size: 26px;
        right: -7.8em;
        top: -3.5em;
        transform: rotate(15deg);
        &:after {
          content: '';
          display: block;
          width: 100px;
          height: 90px;
          position: absolute;
          bottom: -100px;
          margin-left: -20px;
          background: url('/images/icons/left-arrow-with-dash.svg') no-repeat center / contain;
        }
      }
    }
  }
}
