// Cards

//styles for teacher onboarding

.onboarding-panel {
  @extend .offset-md-2;
  @extend .col-8;
  border-radius: 20px;
  box-shadow: $box-shadow;
  padding: 40px;
  text-align: center;
  background-color: $white;

  img {
    max-width: 100%;
    //max-width: 330px;
    margin: auto;
    margin-bottom: 30px;
  }
  p {
    color: $grey-independence;
    margin: 24px auto 32px;
    max-width: 560px;
  }
  h3,
  .h3,
  p {
    margin-left: auto;
    margin-right: auto;
  }
}
