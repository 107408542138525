// Slide here
.lesson-slide.activity-grouping {
  // background-color: $cream-seashell;
  // color: $grey-space-cadet;

  .badges {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &.group-progess {
    flex-direction: column;
    display: flex;
    text-align: center;
    .wrap {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    .percentage {
      font-size: 3vh;
      font-weight: 900;
      &.complete-100 {
        color: $green-say-yes;
      }
    }
    .team-progress {
      display: flex;
      flex-direction: column;
      padding: 4vh;
      border: 4px solid lighten($grey-blackout, 10%);
      border-radius: 30px;
      margin: 40px auto 0;
    }
  }

  h1, .h1 {
    color: $champagne-gold;
  }

  .h3 {
    margin-bottom: 0.5rem;
  }

  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottom-row {
    .btn {
      width: auto;
      margin-top: 30px;
    }
  }
  .student-list {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 10px;
    }
  }
}
