//Globals

img {
  max-width: 100%;
}
.tr,
.tar {
  text-align: right;
}
.tl,
.tal {
  text-align: left;
}
.tac {
  text-align: center;
}

.fw {
  width: 100%;
}
.grey-bg {
  background-color: $grey-cultured-bone;
}

.pr {
  position: relative;
}
.flex {
  display: flex;

  &.flex-vert {
    flex-direction: column;
  }

  &.vert-center {
    align-items: center;
  }

  &.flex-true-center {
    justify-content: center;
    align-items: center;
  }
}
.align-items-flexstart {
  align-items: flex-start;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: 0!important;
}
