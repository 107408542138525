// Sections can be added to any page in any order
section.chairman-message {
  padding: 120px 0;
  background-color: $coral-red;
  color: white;

  .row {
    // align-items: center;
  }

  h3, .h3 {
    font-weight: 900;
    margin-top: 24px;
  }
  .h4 {
    font-weight: 700;
    margin-top: 0;
  }
  p {
    margin-top: 20px;
    font-size: 20px;
    @extend .type-bl;
  }

  .portrait {
    background: url('/images/website/portrait-frame-2.svg') no-repeat center / contain;
    padding: 30px 10%;
    img {
      border-radius: 50%;
    }
  }
}
