// Slide here
.lesson-slide.activity-drag-drop {
  background: $cream-seashell;
  color: $grey-space-cadet;

  p.h5 {
    margin-bottom: 0.5rem;
  }

  .copy {
    @extend h3;
    max-width: 60%;
  }

  .activity-area {
    margin-top: 40px;
    align-items: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .choice {
    background: $white;
    border-radius: 20px;
    padding: 7px 20px;
    @extend .h5;
    margin: 2px 0;

    @include vert-800-up {
      padding: 15px 35px;
      margin: 10px 0;
    }
    &.dragging {
      // transform: rotate(8deg);
      box-shadow: $box-shadow;
    }
  }

  .list {
    min-height: 100px;
    width: 100%;
    border: 2px dashed $grey-manatee;
    border-radius: 20px;
    padding: 5px;
    height: 100%;

    .column-title {
      display: none;
    }
  }

  .bucket {
    @extend .h4;
    border: 2px dashed $grey-space-cadet;
    padding: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    margin-bottom: 16px;
    border-radius: 20px;
    min-height: 100px;
    .column-title {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
    .choice {
      z-index: 2;
    }
  }
  .bottom {
    margin-top: 30px;
  }
}
