// Slide here
.lesson-slide.conclusion-slide {
  > .row {
    width: 100%;
  }
  .copy {
    font-size: 24px;

    @include vert-700-up {
      font-size: 26px;
    }
    @include vert-800-up {
      font-size: 30px;
    }
    @include hd-up {
      font-size: 34px;
    }
  }

  .supplementary-copy {
    font-size: 20px;
  }

  &.theme-copy-small {
    .copy {
      font-size: 24px;
    }
  }
}
