// Slide here
.lesson-slide.text-layout-slide {
  &.cream {
    background: $cream-seashell;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
  .padded-column {
    padding: 0px 16px;
  }
  h2.large,
  .h2.large {
    font-size: 4.5vw;
  }
  h3, .h3 {
    margin-bottom: 40px;
  }
  .row {
    width: 100%;
  }
  .bottom {
    //margin-top: 40px;
    text-align: left;
    width: 100%;
  }
  .fraction {
    font-size: 11vw;
    font-weight: 900;
    color: $green-say-yes;
  }
  .mt-20px {
    margin-top: 20px !important;
  }
  .source {
    @extend .type-bl;
    color: $grey-independence;

    a {
      color: $grey-independence;
    }
  }

  &.media-bias-content {
    color: $grey-space-cadet;
    background-color: $cream-seashell;
    h1, .h1 {
      margin-bottom: 10vh;
    }
    li {
      font-size: 28px;
      margin: 10px 0;
      line-height: 1.2;

      @include hd-up {
        font-size: 36px;
        margin: 20px 0;
      }
    }
    .margin-top {
      margin-top: 10px;
    }
    .charts {
      justify-content: center;
    }

    &.split-background {
      position: relative;
      background-color: $coral-red;
      color: white;

      h2, .h2 {
        margin-bottom: 0;
      }
      .background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 50vw;
        background-color: $green-bee-gee;
        z-index: 1;
      }
      .align-center {
        position: relative;
        z-index: 2;
      }
    }
  }
  &.media-bias-charts {
    h1, .h1 {
      margin-bottom: 5vh;
      @include hd-up {
        margin-bottom: 3vh;
      }
    }
    h3, .h3 {
      font-size: 24px;
      margin-bottom: 10px;
      @include hd-up {
        font-size: 36px;
        margin-bottom: 40px;
      }
    }
  }
  .margin-bottom-5vh,
  h1.margin-bottom-5vh {
    margin-bottom: 5vh
  }
  .margin-bottom-10vh {
    margin-bottom: 10vh;
  }

  &.social-stats {
    height: 100%;
    .content-wrapper {
      column-gap: 40px;
      display: flex;
      height: 100%;
    }
    .social-title    ,
    .social-image {
      height: 100%;

      img {
        height: 100%;
      }
    }
  }
  &.cream-bg {
    color: $grey-space-cadet;
    background-color: $cream-seashell;
  }
  &.convention-rights-child {
    .copy {
      font-size: 22px;
      font-weight: 700;

      @include big-desktop-up {
        font-size: 28px;
      }
    }
    .chart {
      max-height: 90vh;
    }
  }

  &.complaints {
    .row {
      height: 60vh;
    }
    .select {
      button {
        @extend h3;
        margin-right: 15px;
      }
    }
  }

  &.free-speech {
    .universal-rights-img {
      max-height: 80vh;
      box-shadow: $box-shadow;
    }
  }

  &.report-racism {
    h3, .h3 {
      font-size: 26px;
      margin-bottom: 15px;
      @include hd-up {
        font-size: 36px;
      }
    }
    p.h5:not(.margin-bottom-5vh) {
      margin-bottom: 0.5rem;
    }
  }

  &.what-is-media {
    p.h4 {
      margin-bottom: 0.5rem;
    }
    .col-3 {
      text-align: center;
      .media {
        h4, .h4 {
          margin: 0;
        }
        img {
          max-height: 35vh;
        }
      }
    }
  }
}
