// Slide here
$img-height: 85vh;

.lesson-slide.activity-cyberbullying {
  background: $cream-seashell;
  color: $grey-space-cadet;

  .row {
    width: 100%;
    align-items: center;
  }
  .countdown-clock {
    //  color: white;
  }
  p:not(.h5) {
    font-size: 17px;
  }
  .social-reel-wrapper {
    position: relative;
    width: 100%;
    margin: -5vh -4vw;

    .reel {
      position: absolute;
      top: 0;

      right: 0;
      width: 100vw;
      height: 100vh;
      left: 0;
      transition: 0.5s ease;
      transition-delay: 0.3s;
    }
    img {
      //position: absolute;
      transition: 0.3s ease;
      display: block;
      margin: 0;
      height: $img-height;
      width: 100vw;
      opacity: 0.1;
      object-fit: cover;

      @include pc-laptop-up {
        object-fit: fill;
      }
    }
    &.post-0 {
      img:nth-child(1) {
        opacity: 1;
      }
    }
    &.post-1 {
      .reel {
        top: -$img-height;
      }
      img:nth-child(2) {
        opacity: 1;
      }
    }
    &.post-2 {
      .reel {
        top: -$img-height * 2;
      }
      img:nth-child(3) {
        opacity: 1;
      }
    }
    &.post-3 {
      .reel {
        top: -$img-height * 3;
      }
      img:nth-child(4) {
        opacity: 1;
      }
    }
    &.post-4 {
      .reel {
        top: -$img-height * 4;
      }
      img:nth-child(5) {
        opacity: 1;
      }
    }
    &.post-5 {
      .reel {
        top: -$img-height * 5;
      }
      img:nth-child(6) {
        opacity: 1;
      }
    }
    &.post-6 {
      .reel {
        top: -$img-height * 6;
      }
      img:nth-child(6) {
        opacity: 1;
      }
    }
  }

  .bottom-toolbar {
    position: absolute;
    background-color: white;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 10;
    box-shadow: $box-shadow;
    height: 15vh;
    align-items: center;

    .row {
      align-items: baseline;
      min-height: 100%;
    }

    .activity-area {
      display: flex;
      justify-content: space-between;
    }

    .list,
    .bucket {
      min-height: 100%;
      width: calc(50% - 10px);
      border-radius: 8px;
      background: $cream-seashell;
      padding: 5px;
      .column-title {
        font-weight: 900;
        display: block;
        margin-bottom: 10px;
      }
      .choice {
        background: white;
        margin-bottom: 5px;
        border-radius: 5px;
        box-shadow: $box-shadow;
        padding: 5px 10px;
      }
    }

    &.large {
      height: 33vh;
      padding: 20px 5vw;
    }
    h3, .h3 {
      margin: 0;
      margin-right: 1em;
    }

    .btn {
      margin: 0 10px;
      align-items: center;
      display: flex;

      .i {
        margin-right: 10px;
        box-shadow: $box-shadow;
        border-radius: 50%;
      }
    }
  }
}
