// Lesson plan page
.lesson-plan-page {
  background: $grey-feedbacker;

  .intro-header {
    background: $blue-all-in;
    color: $white;
    padding: 128px 0;
    h2,
    .h2 {
      margin: 0;
    }
  }
  .plan {
    padding: 88px 0;
  }

  .section {
    background: $white;
    border-radius: 8px;
    margin-bottom: 80px;
    header {
      padding: 20px 40px;
      border-radius: 8px 8px 0 0;
      display: flex;
      justify-content: space-between;

      @include topic-colours();
    }
    .card-content {
      padding: 48px 40px;
      .tag {
        margin: 3px 2px;
        display: inline-block;
        text-decoration: none;
      }
    }
    .labels {
      margin-top: 32px;
    }

    .hr {
      height: 1px;
      width: calc(100% + 80px);
      margin: 40px -40px;
      background: $grey-feedbacker;
    }
    .hr-dashed {
      height: 1px;
      width: calc(100% + 80px);
      margin: 40px -40px;
      border-bottom: 1px dashed $grey-independence;
    }
  }

  .lesson-step {
    padding: 40px 0;
    border-bottom: 1px solid $grey-feedbacker;

    .slides {
      margin: 2rem 0;
      display: flex;
      flex-wrap: wrap;
      .slide {
        width: calc(20% - 5px);
        margin: 0 5px 5px 0;
        cursor: pointer;
      }
    }
    .description {
      color: $grey-manatee;
    }
  }
  .button-set {
    margin-top: 40px;

    .btn {
      font-size: 14px;
      padding: 10px 25px;
      border: 1px solid $grey-manatee;
      margin-bottom: 8px;
      display: block;

      &.student-screens {
        border-color: $green-bee-gee;
        color: $green-bee-gee;
        background: rgba($green-bee-gee, 5%);
      }
      &.teaching-notes {
        border-color: $blue-all-in;
        color: $blue-all-in;
        background: rgba($blue-all-in, 10%);
      }

      &.disabled {
        font-weight: normal;
      }

      .notes {
        text-align: left;
        font-weight: normal;
        padding: 40px $gutter 20px;
        color: $grey-space-cadet;
        h5,
        .h5 {
          margin-bottom: 20px;
        }
      }
    }
  }

  table {
    margin-bottom: 20px;
    //border: 1px solid $grey-loveheart;
    tr {
      border-bottom: 1px solid $grey-loveheart;
      td {
        padding: 5px;
      }
    }
  }

  .table {
    width: 100%;
    .thead {
      font-weight: 700;
    }
  }

  .dropdown {
    background: white;
    color: $grey-space-cadet;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    padding: 10px 55px 10px 15px;
    font-size: 18px;
    &:after,
    &:before {
      display: block;
      content: '';
      width: 1px;
      height: 15px;
      background: $grey-space-cadet;
      position: absolute;
      right: 20px;
      top: 15px;
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
      right: 30px;
    }

    .dropdown-box {
      position: absolute;
      background: white;
      padding: 10px 15px;
      right: 0;
      left: 0;
      top: 35px;
      border-radius: 3px;
      z-index: 1001;

      li {
        list-style: none;

        @include topic-colours;
        border-radius: 3px;
        margin: 3px 0;
        a {
          color: $grey-blackout;
          font-weight: 700;
          padding: 5px 10px;
          display: block;
          text-decoration: none;
        }
      }
    }
  }
  .dropdown-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
}
