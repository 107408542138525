// Slide here
.lesson-slide.activity-results-bar-slide {
  background-color: $cream-seashell;
  color: $grey-space-cadet;
  h1 {
    font-family: AesteticoInformal;
    max-width: 70%;
    margin-bottom: 5vh;
  }
  &.with-image h1 {
    max-width: revert;
  }

  h1:not(.h2, .h3, .h4, .h5, .h6),
  .h1 {
    margin: auto;
  }
  .charts {
    margin-top: 40px;

    .rail {
      width: 100%;
    }

    &.theme-multiple {
      .rail {
        &:nth-child(2) .bar {
          background-color: $green-say-yes;
        }
        &:nth-child(3) .bar {
          background-color: $blue-sky-crayola;
        }
        &:nth-child(4) .bar {
          background-color: $pink-you-and-me;
        }
      }
    }

    &.theme-many {
      .bar {
        height: 7vh;
        min-width: 25vw;
        padding-left: 25px;
        img {
          display: none;
        }
        .score,
        .answer {
          font-size: 1.8vw;
        }
      }
    }

    .bar {
      background-color: $blue-all-in;
      padding: 12px;
      border-radius: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      min-width: 300px;

      &.Yes,
      &.Agree {
        background-color: $green-say-yes;
      }
      &.No,
      &.Disagree {
        background-color: $coral-red;
      }

      &.No,
      &.Yes {
        min-width: 160px;
      }

      &.Disagree,
      &.Agree {
        min-width: 385px;
      }
      &.rabbit,
      &.duck {
        min-width: 385px !important;
      }
    }
    img {
      height: 10vh;
      border-radius: 50%;
    }
    .score,
    .answer {
      @extend h3;
      color: $white;
      font-weight: 900;
      line-height: 1;
      margin: 0 20px 0 0;
      white-space: nowrap;
    }
    .answer {
      margin: 0;
    }

    &.full-bars {
      .bar {
        &.No,
        &.Yes {
          min-width: 300px;
        }
      }
    }
  }
}

.tall-image {
  max-height: 80vh;
}

.lesson-slide.activity-results-social-media {
  h1,
  .h1 {
    margin-bottom: 3vh;
  }
  h4,
  .h4 {
    text-align: left;
    margin-top: 20px;
  }
  .charts {
    margin-top: 20px;
    .bar {
      margin-top: 5px;
      min-width: 320px;
    }
    .score,
    .answer {
      font-size: 22px;
    }
    .answer {
      margin: 0 10px;
    }
    .score {
      margin-right: 0;
    }
  }
  .national-stats {
    margin-top: 40px;
  }
  p {
    text-align: left;
    margin-top: 10px;
    font-size: 22px;
    max-width: 70%;
  }
}

.lesson-slide.activity-results-human-rights {
  .charts {
    .row {
      width: 100%;
      display: flex;
      margin: 10px 0;
      .label {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .answer {
          color: $grey-blackout;
          font-size: 20px;
          margin-right: 10px;
        }
      }
      .rail {
        width: 50%;
      }

      &.highlighted {
        .answer {
          color: $coral-red;
        }
        .bar {
          background-color: $coral-red;
        }
      }
    }
    .bar {
      margin-top: 0;
      justify-content: flex-end;
      min-width: 95px;
    }
    .score {
      font-size: 20px;
    }
  }
}

.lesson-slide.activity-results-cyber-racism {
  .offset-img {
    margin: 0 -8vw -9vh 0;
    max-height: 80vh;
  }
  .real-answer {
    margin-top: 5vh;
  }
}

.lesson-slide.activity-results-class-diversity {
  background-color: $cream-seashell;
  color: $grey-space-cadet;

  .tac {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  h1.h2 {
    font-family: AesteticoInformal;
    margin-bottom: 1em;
  }
  .answer-set {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 60px;
  }
  .answer {
    @extend h4;
    color: white;
    background: $green-bee-gee;
    font-weight: 900;
    border-radius: 40px;
    padding: 10px 10px 10px 20px;
    display: inline-block;
    margin: 5px;
    position: relative;
    display: flex;
    align-items: center;
    .count {
      @extend h5;
      background-color: white;
      color: $green-bee-gee;
      padding: 5px 14px;
      font-weight: 900;
      border-radius: 30px;
      margin: 0 0 0 12px;
    }

    &.size-1 {
      background: $blue-all-in;
      .count {
        color: $blue-all-in;
      }
    }
    &.size-2 {
      background: $pink-you-and-me;
      color: darken($pink-you-and-me, 50%);
      .count {
        color: darken($pink-you-and-me, 50%);
      }
    }
    &.size-3 {
      background: $faded-yellow;
      color: darken($faded-yellow, 40%);
      .count {
        color: darken($faded-yellow, 40%);
      }
    }
    &.size-4 {
      color: darken($blue-sky-crayola, 40%);
      background: $blue-sky-crayola;
      .count {
        color: darken($blue-sky-crayola, 40%);
      }
    }
    &.size-5 {
      background: $topic-media;
      color: darken($topic-media, 20%);
      .count {
        color: darken($topic-media, 20%);
      }
    }
    &.size-6 {
      color: darken($topic-discrimination, 50%);
      background: $topic-discrimination;
      .count {
        color: darken($topic-discrimination, 50%);
      }
    }
    &.size-7 {
      color: darken($topic-online, 40%);
      background: $topic-online;
      .count {
        color: darken($topic-online, 40%);
      }
    }
    &.size-8 {
      color: darken($champagne-gold, 50%);
      background: $champagne-gold;
      .count {
        color: darken($champagne-gold, 50%);
      }
    }
  }
}
