// Slide here
.lesson-slideshow {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $grey-blackout;
  color: white;
  outline: none;
  overflow: hidden;

  // * {
  //   user-select: none;
  // }
}
.lesson-slide {
  width: 100vw;
  height: 100vh;
  padding: 5vh 4vw;
  display: flex;

  &.bg-cream {
    background-color: $cream-seashell;
    color: $grey-space-cadet;
  }
  @include topic-colours();

  div[class^='topic-'] {
    color: $grey-space-cadet;
  }

  .btn {
    font-size: 20px;
    letter-spacing: 0.05em;
  }
  .arrow {
    font-size: 1rem;
  }
  .align-center {
    align-self: center;
    width: 100%;
  }

  .incomplete-label {
    position: absolute;
    transform: rotate(45deg);
    background-color: $red-error;
    color: white;
    text-align: center;
    padding: 15px 200px;
    font-size: 20px;
    font-weight: 900;
    left: -170px;
    bottom: 80px;

    &.illustrations {
      background-color: $orange-warning;
    }
  }
}

.page-number {
  position: absolute;
  top: 0px;
  left: 0px;
  //background: rgba(255, 255, 255, 0.8);
  padding: 5px 15px 15px 5px;
  border-radius: 0 0 30px 0;
  font-size: 10px;
  color: $grey-independence;
  opacity: 0.5;
}
