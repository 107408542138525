// Sections can be added to any page in any order
section.text-intro {
  padding: 120px 0;
  background-color: $grey-cultured-bone;
  text-align: center;
  h3, .h3 {
    font-weight: 900;
    margin-top: 24px;
  }
  p {
    @extend .type-bl;
  }
}
