// Wordcloud

.wordcloud {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  .word {
    background: $grey-cultured-bone;
    padding: 10px 30px;
    border-radius: 50px;
    margin: 5px 10px;
    font-weight: 900;
    color: $grey-space-cadet;
    &.size-1 {
      font-size: 30px;
      background-color: lighten($blue-all-in, 10%);
    }
    &.size-2 {
      font-size: 34px;
      background-color: lighten($blue-all-in, 15%);
    }
    &.size-3 {
      font-size: 38px;
      background-color: lighten($blue-all-in, 20%);
    }
    &.size-4 {
      font-size: 42px;
      background-color: lighten($blue-all-in, 25%);
    }
    &.size-5 {
      font-size: 46px;
      background-color: lighten($blue-all-in, 30%);
    }
    &.size-6 {
      font-size: 50px;
      background-color: lighten($blue-all-in, 35%);
    }
    &.size-7 {
      font-size: 54px;
      background-color: lighten($blue-all-in, 40%);
    }
    &.size-8 {
      font-size: 58px;
      background-color: lighten($blue-all-in, 35%);
    }
  }
}
