// Slide here
.lesson-slide.title-number-slide {
  background-color: $cream-seashell;

  h1, .h1 {
    font-size: 90px;
    font-size: 5vw;
    font-weight: 900;
    //max-width: 60%;
    color: $blue-all-in;
    margin: 0;
    position: relative;
    z-index: 5;
  }
  h2, .h2 {
    color: $blue-all-in;
    font-size: 3vw;
  }

  .row {
    width: 100%;
  }
  .col-7 {
    align-items: center;
    display: flex;
  }
  .number {
    z-index: 10;
    position: relative;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  .number {
    color: $blue-sky-crayola;
    font-size: 400px;
    font-weight: 900;

    &.fraction {
      position: relative;
      font-size: 300px;
      .top {
        position: absolute;
        top: -0.55em;
        right: 0.8em;
      }
      .middle {
        position: absolute;
        right: 0.55em;
        top: -0.2em;
        width: 50px;
        height: 400px;
        transform: rotate(45deg);
        background-color: $blue-sky-crayola;
      }
      .bottom {
        position: absolute;
        top: 0.2em;
        right: 0;
      }
    }
  }

  @for $i from 1 through 6 {
    &.background-#{$i} {
      &:after {
        background: url(/images/slides/shapes/list-#{$i}-off-right.svg) no-repeat right / contain;
        opacity: 0.7;
      }
    }
  }

  &.title-number-illustration-slide {
    .background-image,
    .background-image img {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
    .caption {
      position: absolute;

      left: 5vw;
      background-color: rgba(white, 0.9);
      border-radius: 20px;
      max-width: 70vw;
      padding: 2vh 2vw;
      display: flex;
      align-items: center;

      &.undefined {
        bottom: 10vh;
      }

      &.top {
        top: 10vh;
      }
    }
    .number {
      font-size: 10vh;
      margin: 0 3vw 0 1.5vw;
      line-height: 1;
    }
    h1, .h3 {
      font-size: 50px;
      font-size: 6.5vh;
      font-weight: 900;
      //max-width: 60%;
      color: $blue-all-in;
      margin: 0;
      position: relative;
      z-index: 5;
    }
  }
}
