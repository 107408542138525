// Slide here
.lesson-slide.statement-slide {
  background-color: $cream-seashell;

  h2, .h2 {
    color: $blue-all-in;
  }

  .row {
    width: 100%;
  }
  .col-6 {
    align-items: center;
    display: flex;
    &.vertical-center {
      flex-direction: column;
      h1, .h2,
      h3, .h3 {
        width: 100%;
      }
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: url(/images/slides/shapes/list-1-off-right.svg) no-repeat right / contain;
  }

  &.theme-blue {
    background-color: $blue-all-in;
    h2, .h2 {
      color: $white;
    }
  }
  &.theme-red {
    background-color: $coral-red;
    h2, .h2 {
      color: $white;
    }
  }
  &.theme-green {
    background-color: $green-say-yes;
    h2, .h2 {
      color: $white;
    }
  }

  &.theme-undefined {
    h3, .h3 {
      color: $blue-all-in;
    }
  }

  &.theme-dark-green {
    background-color: $green-bee-gee;
    h2, .h2 {
      color: $white;
    }
  }
}
