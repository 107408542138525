// Slide here
.lesson-slide.image-slide {
  .image {
    display: block;
    margin: auto;
    width: 80%;
    max-height: 70vh;
    object-fit: contain;
  }

  .floating-label {
    background-color: $grey-space-cadet;
    color: white;
    display: inline-block;
    position: absolute;
    bottom: 5%;
    left: 5%;
    line-height: 1;
    padding: 20px 40px;
  }

  &.image-poll {
    background: $cream-seashell;
    color: $grey-space-cadet;

    .container {
      display: flex;
      align-items: center;

      .offset-md-1 {
        display: flex;
        align-items: center;
      }
    }
    .social-post {
      border-radius: 10px;
      box-shadow: $box-shadow;
    }
  }
}
