// Assessment Screen

.assessment-screen {
  .main-panel {
    background: white;
    border-radius: 8px;
    margin-bottom: 80px;
    box-shadow: $box-shadow;

    header {
      padding: 20px 40px;
      border-radius: 8px 8px 0 0;
      display: flex;
      justify-content: space-between;

      @include topic-colours();
    }
    .card-content {
      padding: 48px 40px;
    }
    .module-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .tag.completed {
      color: white;
      background: $green-say-yes;
      border: none;
      white-space: nowrap;
      margin-top: 8px;
    }
    .summary {
      padding: 60px 0 0;
      margin-top: 40px;
      border-top: 1px solid $grey-feedbacker;

      div[class^='col-'] {
        display: flex;
        flex-direction: column;
      }
    }
    .stat {
      @extend h4;
      @extend .xb;
      margin-top: 10px;
    }
    .cell {
      border-radius: 10px;
      background-color: $grey-cultured-bone;
      padding: 20px 25px;
      margin-bottom: 15px;
      flex: 1;
      .label {
        @extend .type-ui;
        line-height: 1;
        margin-bottom: 10px;
      }
    }

    .chart {
      display: flex;
      height: 200px;
      text-align: center;
      align-items: flex-end;
      .bar {
        width: calc(25% - 12px);
        min-height: 60px;
        border-radius: 8px;
        padding: 10px;
        margin: 0 6px;
        background-color: $grey-loveheart;
        text-transform: capitalize;
      }
    }
    // ul {
    //   margin: 0;
    //   padding: 0;
    //   li {
    //     list-style: none;
    //   }
    // }

    .student-list {
      padding: 20px 20px 0 0;
      ul {
        margin: 0;
        padding: 0;
      }
      li {
        background-color: $grey-cultured-bone;
        border-radius: 8px;
        padding: 0;
        margin-bottom: 10px;
        list-style: none;
        cursor: pointer;
        font-weight: 900;
        font-size: 22px;

        button {
          background: transparent;
          color: inherit;
          font: inherit;
          display: flex;
          justify-content: flex-start;
          padding: 15px 20px;
          width: 100%;
          align-items: center;
        }

        .avatar-preview img {
          left: 0;
          right: 0;
          margin: auto;
        }

        div {
          pointer-events: none;
        }

        &.selected {
          background-color: $champagne-gold;
        }
      }
    }
    .answers {
      padding-top: 20px;

      strong {
        font-weight: 900;
      }
      .answers-heading {
        margin: 10px 0 0;
      }
      .nps {
        margin: 20px 0;
        font-size: 20px;
        background: $grey-cultured-bone;
        border-radius: 8px;
        padding: 10px 15px;
      }

      > ul {
        padding: 0;
      }
    }
  }

  .answer {
    border: 1px solid $grey-feedbacker;
    border-radius: 8px;
    padding: 15px 20px;
    margin: 30px 0 0;
    list-style: none;
    font-size: 18px;
    .name {
      text-transform: capitalize;
    }
    .selected-answer {
      font-weight: bold;
    }

    .video {
      max-width: 100%;
    }
    ul {
      padding: 0 0 0 20px;
      li {
        margin-bottom: 5px;
      }
    }

    img {
      display: block;
      margin: 20px 0 10px;
    }

    p {
      margin: 1rem 0;
    }

    h3,
    .h3 {
      margin-top: 0;
    }

    h4,
    .h4 {
      margin-top: 20px;
      &:first-child {
        margin-top: 10px;
      }
    }

    h5,
    .h5 {
      margin-bottom: 20px;

      ~ h5,
      .h5 {
        margin-top: 40px;
      }
    }
    .image-response {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      padding-left: 0;

      li {
        width: calc(50% - 10px);
        list-style: none;
        margin: 5px;
        padding: 20px;
        background: $grey-cultured-bone;
        border-radius: 8px;

        ol {
          li {
            padding: 0 5px;
            list-style: decimal;
          }
        }
        image {
          //max-width: calc(100% - 40px);
        }
      }

      &.wide li {
        width: 100%;
      }
      .cyber-img-wrap {
        background: $cream-seashell;
        border-radius: 5px;
      }
    }
  }
  figure {
    border: 1px solid $grey-loveheart;

    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    figcaption {
      margin: 0 5px 5px;
    }
  }
  p .answer-tag {
    margin: 10px;
  }
  .answer-tag {
    color: white;
    background: $blue-all-in;
    border-radius: 3px;
    padding: 1px 10px;
    display: inline-block;
    &.c-green {
      background: $green-bee-gee;
    }
    &.c-red {
      background: $coral-red;
    }
  }
}
