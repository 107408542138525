// Slide here
.lesson-slide.activity-image-reveal {
  background: $cream-seashell;
  color: $grey-space-cadet;

  .row {
    width: 100%;
  }

  .vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  textarea {
    min-height: 20vh;
  }

  .answer {
    @extend .type-bl;
    background: white;
    border-radius: 16px;
    margin-bottom: 10px;
    padding: 25px 30px;
  }
}
