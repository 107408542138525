// Controls here

.exit-lesson {
  color: $grey-space-cadet;
  border-radius: $br-large;
  background: $white;
  font-size: 16px;
  position: absolute;
  top: 5vh;
  right: 5vw;
  transition: $transition;
  z-index: 100;

  &.inverted {
    background-color: $grey-space-cadet;
    color: white;
    &:hover {
      background-color: lighten($grey-space-cadet, 10%);
    }
  }

  &:hover {
    transform: scale(1.05);
    background-color: white;
    color: $grey-space-cadet;
  }
}

.lesson-slide-controls,
.student-counter-control {
  position: absolute;
  right: 5vw;
  bottom: 5vh;
  background: $white;
  border-radius: 8px;
  padding: 23px 20px;
  box-shadow: $box-shadow;
  display: flex;
  z-index: 100;
  path {
    transition: $transition;
  }
  .arrow {
    background-color: transparent;
    padding: 0 10px;
    transition: $transition;
    &:hover {
      background-color: transparent;
    }
    &:not(.disabled) {
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
        path {
          fill: darken($green-say-yes, 15%);
        }
      }
    }
  }
  .timer {
    padding: 0 10px;
  }

  .finish-lesson {
    margin: -14px -10px -14px 10px;
    font-size: 20px;
    letter-spacing: 0.05em;
  }
}

.student-counter-control {
  left: 5vw;
  right: auto;
  display: flex;
  align-items: center;

  .rail {
    height: 4px;
    width: 180px;
    border-radius: 2px;
    background: rgba($blue-all-in, 20%);
    margin: 0 25px;
  }
  .bar {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: $blue-all-in;
    transition: $transition;
    max-width: 100%;
  }
  .count {
    @extend h4;
    margin: 0;
    font-weight: 900;
    color: $grey-manatee;
  }
  .current {
    color: $blue-all-in;
  }
  .divider {
    font-weight: 100;
  }
  &.completed {
    //animation: name duration timing-function delay iteration-count direction fill-mode;
    animation: wobble 1.5s ease-in-out 0s infinite forwards;
    path {
      fill: $green-say-yes;
    }
    .bar {
      background: $green-say-yes;
    }
    .current {
      color: $green-say-yes;
    }
  }
}

@keyframes wobble {
  0% {
    transform: rotate(0) translate3d(0, 0, 0);
  }
  10% {
    transform: rotate(2deg) translate3d(1px, 0, 0);
  }
  20% {
    transform: rotate(-1deg) translate3d(0, -1px, 0);
  }
  30% {
    transform: rotate(1deg) translate3d(-1px, 0, 0);
  }
  40% {
    transform: rotate(-2deg) translate3d(-1px, -1px, 0);
  }
  50% {
    transform: rotate(0) translate3d(0, 0, 0);
  }
}
