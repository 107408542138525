// Slide here
.lesson-slide.activity-social-posts {
  .row {
    width: 100%;
    align-items: center;
  }
  .countdown-clock {
    color: white;
  }
  p {
    font-size: 17px;
  }
  .social-reel-wrapper {
    position: absolute;
    width: 30vw;
    top: 5vh;
    left: 10vw;
    &.full-height {
      top: 0;
      bottom: 0;
      width: 50vw;
      left: 5vw;
      .reel > img {
        height: 100vh;
        margin: 0 auto;
      }
      &.post-1 {
        .reel {
          top: -100vh;
        }
      }
      &.post-2 {
        .reel {
          top: -200vh;
        }
      }
      &.post-3 {
        .reel {
          top: -300vh;
        }
      }
    }

    &.discrimination-wrapper {
      width: 48vw;
      left: 5vw;
    }
    .reel {
      position: absolute;
      top: 0vh;
      width: 100%;
      left: 0;
      transition: 0.5s ease;
      transition-delay: 0.3s;

      > img {
        //position: absolute;
        transition: 0.3s ease;
        display: block;
        margin: 5vh auto;
        opacity: 0.1;
        border-radius: 12px;
        height: 90vh;
        object-fit: contain;
      }
    }

    // used for dog breed activity
    .slide {
      //position: absolute;
      transition: 0.3s ease;
      display: block;
      margin: 0 auto 10vh;
      opacity: 0.1;
      border-radius: 12px;
      height: 90vh;
      object-fit: contain;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: white;

      &.discrimination {
        background-color: $cream-seashell;
        padding: 10px;
      }

      img {
        width: 100%;
        object-fit: contain;
        margin-bottom: 20px;
      }
    }
    &.post-0 {
      img:nth-child(1) {
        opacity: 1;
      }
      .slide:nth-child(1) {
        opacity: 1;
      }
    }
    &.post-1 {
      .reel {
        top: -100vh;
        > img:nth-child(2) {
          opacity: 1;
        }
        .slide:nth-child(2) {
          opacity: 1;
        }
      }
    }
    &.post-2 {
      .reel {
        top: -195vh;
        > img:nth-child(3) {
          opacity: 1;
        }
        .slide:nth-child(3) {
          opacity: 1;
        }
      }
    }
    &.post-3 {
      .reel {
        top: -290vh;
        > img:nth-child(4) {
          opacity: 1;
        }
        .slide:nth-child(4) {
          opacity: 1;
        }
      }
    }
    &.post-4 {
      .reel {
        top: -385vh;
        > img:nth-child(5) {
          opacity: 1;
        }
        .slide:nth-child(5) {
          opacity: 1;
        }
      }
    }
    &.post-5 {
      .reel {
        top: -480vh;
        > img:nth-child(6) {
          opacity: 1;
        }
        .slide:nth-child(6) {
          opacity: 1;
        }
      }
    }
  }

  &.thoughtful-posts {
    .btn-group .btn,
    .btn-group button {
      padding: 13px 50px;
    }
  }
}
