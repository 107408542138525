// Sections can be added to any page in any order
section.two-col {
  padding: 30px 0;

  @include tablet-portrait-up {
    padding: 120px 0;
  }

  + section.two-col {
    padding-top: 0;
  }
  h3, .h3 {
    font-weight: 900;
    margin-top: 24px;
  }
  p {
    @extend .type-bl;
  }
  .shadow {
    box-shadow: $box-shadow;
  }
  .btn {
    margin: 24px 0 18px;
  }

  .row {
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
    }
    .col {
      @include tablet-portrait-up {
        width: 48%;
        flex: none;
      }
      img {
        width: 100%;
      }
    }
  }

  &.flip .row {
    @include tablet-portrait-up {
      flex-direction: row-reverse;
    }
  }

  .one-col-center + & {
    padding-top: 0;
  }
}
