// Slide here
.lesson-slide.tips-slide {
  .row {
    padding: 70px 0;

    img {
      height: 150px;
      margin-bottom: 20px;
    }
  }

  div[class^='tip-'] {
    @extend .h5;
    max-width: 286px;
    position: absolute;
    &:after {
      position: absolute;
      content: '';
      display: block;
    }
  }
  .tip-arrows {
    right: calc(5% + 117px);
    bottom: calc(5% + 110px);

    &:after {
      background: url('/images/slides/tips/skip-arrow.svg') no-repeat center;
      width: 52px;
      height: 39px;
      right: -60px;
      top: 10px;
    }
  }
  .tip-timer {
    right: calc(5% + -40px);
    bottom: calc(5% + 176px);

    &:after {
      background: url('/images/slides/tips/timer-arrow.svg') no-repeat center;
      width: 28px;
      height: 85px;
      right: 82px;
      top: 55px;
    }
  }

  .tip-exit {
    right: calc(5% + 128px);
    top: calc(5% + 115px);

    &:after {
      background: url('/images/slides/tips/end-session-arrow.svg') no-repeat center;
      width: 37px;
      height: 58px;
      right: -48px;
      top: -56px;
    }
  }

  .lesson-slide-controls,
  .student-counter-control {
    pointer-events: none;
  }
}
