// Slide here
.lesson-slide.take-home-slide {
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 1.5s ease 1s 1 forwards;
    background: url(/images/slides/shapes/take-home.svg) no-repeat bottom left / contain;
    pointer-events: none;
    width: 730px;
    height: 100vh;
  }

  p {
    @extend h3;
    margin-bottom: 40px;
  }
}
