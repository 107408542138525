// Slide here
.lesson-slide.bullying-roles-slide {
  background: $cream-seashell;
  color: $grey-space-cadet;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  .roles {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    align-items: center;
  }
  .role {
    width: 17vw;
    border-radius: 40px;
    padding: 20px;
    text-transform: uppercase;
    color: white;
    margin: 10px;
    text-align: center;
    box-shadow: $box-shadow;
    &.reinforcer {
      background: $coral-red;
      transform: rotate(-10deg);
    }
    &.sidekick {
      background: $blue-all-in;
      transform: rotate(1deg);
    }
    &.upstander {
      background: $green-say-yes;
      transform: rotate(8deg);
    }
    &.outsider {
      background: $faded-yellow;
      transform: rotate(-15deg);
    }

    .label {
      @extend h4;
      display: block;
      margin: 2vh 0;
    }
  }
}
