// Slide here
.lesson-slide.activity-think-feel-care {
  background: $cream-seashell;
  color: $grey-space-cadet;

  .josie-bio {
    padding: 5vw;
    p {
      @extend h4;
      margin-bottom: 20px;
    }
    button {
      display: inline-block;
      margin-top: 30px;
      position: relative;

      @include media-breakpoint-up(md) {
        position: absolute;
      }
    }
  }

  .comic-frame {
    margin: -5vh -4vw;
    display: flex;
    width: 100vw;
    height: 100vh;
    position: relative;

    .left {
      width: 50%;
      background: $topic-online;
      display: flex;
      align-items: flex-end;
      img {
        opacity: 0;
        animation: fadeIn 0.5s ease 0.5s 1 normal forwards; //  delay iteration-count direction fill-mode;
      }
    }
    .right {
      width: 50%;
      display: flex;
      align-items: flex-end;
      svg {
        opacity: 0;
        animation: fadeIn 0.5s ease 3s 1 normal forwards; //  delay iteration-count direction fill-mode;
      }
    }
    .reading-image {
      enable-background: new 0 0 950.6 1013.2;
      .st0 {
        display: none;
        fill: none;
        stroke: #80ebff;
        stroke-width: 2;
        stroke-miterlimit: 10;
      }
      .background {
        fill: darken($cream-seashell, 10%);
        transition: 0.5s ease;
      }
      .st2 {
        fill: #6b98f5;
      }
      .st3 {
        fill: #333344;
        stroke: #333344;
        stroke-width: 36.81;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 36.81;
      }
      .st4 {
        fill: #ffe690;
      }
      .st5 {
        fill: none;
        stroke: #ff7580;
        stroke-width: 3.98;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }
      .st6 {
        fill: #ffbdff;
      }
      .st7 {
        fill: #ffbdff;
        stroke: #1d1d1b;
        stroke-width: 5.29;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
      .st8 {
        fill: #ffbdff;
        stroke: #333344;
        stroke-width: 3.98;
        stroke-miterlimit: 10;
      }
      .st9 {
        fill: #ff7580;
      }
      .st10 {
        fill: #35e1c0;
      }
      .st11 {
        fill: #333344;
      }
      .st12 {
        fill: none;
        stroke: #333344;
        stroke-width: 36.81;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }
      .st13 {
        fill: none;
        stroke: #333344;
        stroke-width: 36.81;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 32.6;
      }
      .st14 {
        fill: none;
        stroke: #333344;
        stroke-width: 36.81;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 20.74;
      }
      .st15 {
        fill: none;
        stroke: #333344;
        stroke-width: 36.81;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 39.89;
      }
      .st16 {
        fill: none;
        stroke: #333344;
        stroke-width: 36.81;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 38.51;
      }
      .st17 {
        fill: none;
        stroke: #333344;
        stroke-width: 36.81;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 42.47;
      }
      .st18 {
        fill: none;
        stroke: #333344;
        stroke-width: 36.81;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 36.81;
      }
      .st19 {
        fill: none;
        stroke: #333344;
        stroke-width: 36.81;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 34.18;
      }
      .st20 {
        opacity: 1;
        transition: 0.5s ease;
      }
      .st21 {
        display: inline;
        fill: none;
        stroke: #333344;
        stroke-width: 3.98;
        stroke-miterlimit: 10;
      }
      .st22 {
        display: inline;
        fill: #333344;
      }
      .st23 {
        display: inline;
        fill: none;
        stroke: #333344;
        stroke-width: 5.33;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
      .st24 {
        display: inline;
        fill: #ffffff;
      }

      .st25 {
        display: inline;
        fill: none;
        stroke: #333344;
        stroke-width: 5.3333;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st26 {
        display: inline;
        fill: none;
        stroke: #333344;
        stroke-width: 3.9762;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }
      .st27 {
        display: inline;
        fill: none;
        stroke: #333344;
        stroke-width: 3.9762;
        stroke-miterlimit: 10;
      }
      .st28 {
        fill: none;
        stroke: #333344;
        stroke-width: 3.9762;
        stroke-miterlimit: 10;
      }
      .st29 {
        fill: #abc4f9;
      }
      .st30 {
        fill: none;
        stroke: #333344;
        stroke-width: 5.3333;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
    }

    .ethan-image {
      .st0 {
        fill: none;
      }
      .st1 {
        fill: #1d1d1b;
      }
      .st2 {
        fill: #f6b4b4;
      }
      .st3 {
        fill: #6b98f5;
      }
      .st4 {
        clip-path: url(#SVGID_00000037677638902465216820000010618767202587764098_);
        fill: #ffffff;
      }
      .st5 {
        clip-path: url(#SVGID_00000037677638902465216820000010618767202587764098_);
        fill: #80ebff;
      }
      .st6 {
        clip-path: url(#SVGID_00000037677638902465216820000010618767202587764098_);
      }
      .st7 {
        fill: url(#SVGID_00000025404170444732559370000006924773878618294145_);
      }
      .st8 {
        fill: none;
        stroke: #f4efe8;
        stroke-width: 1.4311;
        stroke-miterlimit: 10;
      }
      .st9 {
        fill: #34c2a0;
      }
      .st10 {
        fill: none;
        stroke: #f4efe8;
        stroke-width: 1.3949;
        stroke-miterlimit: 10;
      }
      .st11 {
        fill: #ffe074;
      }
      .st12 {
        fill: #35e1c0;
      }
      .st13 {
        fill: none;
        stroke: #ff7580;
        stroke-width: 1.1246;
        stroke-miterlimit: 10;
      }

      .st14 {
        clip-path: url(#SVGID_00000037677638902465216820000010618767202587764098_);
        fill: none;
        stroke: #80ebff;
        stroke-width: 1.1269;
        stroke-miterlimit: 10;
      }
      .st15 {
        fill: #ff7580;
      }
      .st16 {
        opacity: 0.6;
        fill: none;
        stroke: #333344;
        stroke-miterlimit: 10;
      }
      .st17 {
        fill: #333344;
        stroke: #1d1d1b;
        stroke-width: 0.8955;
        stroke-miterlimit: 10;
      }
      .st18 {
        fill: #95959e;
      }
      .st19 {
        fill: #ffffff;
      }
      .st20 {
        fill: none;
        stroke: #80ebff;
        stroke-width: 1.1269;
        stroke-miterlimit: 10;
      }
      .st21 {
        fill: #ffffff;
        stroke: #1d1d1b;
        stroke-width: 0.6567;
        stroke-miterlimit: 10;
      }
      .st22 {
        fill: #333344;
        stroke: #1d1d1b;
        stroke-width: 0.2821;
        stroke-miterlimit: 10;
      }
      .st23 {
        fill: none;
        stroke: #1d1d1b;
        stroke-miterlimit: 10;
      }
      .st24 {
        fill: #932782;
      }
      .st25 {
        fill: #80ebff;
      }
      .st26 {
        fill: #f4efe8;
      }
      .st27 {
        fill: none;
        stroke: #f4efe8;
        stroke-width: 1.1776;
        stroke-miterlimit: 10;
      }
      .st28 {
        fill: #333344;
      }
      .st29 {
        fill: none;
        stroke: #1d1d1b;
        stroke-width: 1.6551;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st30 {
        fill: none;
        stroke: #2e2d2b;
        stroke-width: 0.8112;
        stroke-miterlimit: 10;
      }
      .st31 {
        fill: #e5579e;
        stroke: #2e2d2b;
        stroke-width: 0.8112;
        stroke-miterlimit: 10;
      }
      .st32 {
        fill: #e5579e;
        stroke: #2e2d2b;
        stroke-width: 1.6223;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st33 {
        fill: none;
        stroke: #333344;
        stroke-width: 1.0767;
        stroke-miterlimit: 10;
      }
      .st34 {
        fill: none;
        stroke: #333344;
        stroke-width: 0.9408;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st35 {
        fill: none;
        stroke: #2e2d2b;
        stroke-width: 0.7953;
        stroke-miterlimit: 10;
      }
      .st36 {
        fill: #e5579e;
        stroke: #2e2d2b;
        stroke-width: 0.7953;
        stroke-miterlimit: 10;
      }
      .st37 {
        fill: #e5579e;
        stroke: #2e2d2b;
        stroke-width: 1.5907;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st38 {
        fill: none;
        stroke: #333344;
        stroke-width: 1.0557;
        stroke-miterlimit: 10;
      }
      .st39 {
        fill: none;
        stroke: #333344;
        stroke-width: 1.2103;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st40 {
        fill: none;
        stroke: #333344;
        stroke-width: 2.0042;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st41 {
        fill: none;
        stroke: #35e1c0;
        stroke-width: 10.8015;
        stroke-miterlimit: 10;
      }
      .st42 {
        fill: none;
        stroke: #5c5c69;
        stroke-width: 1.2002;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }
      .st43 {
        fill: none;
        stroke: #333344;
        stroke-width: 2.5225;
        stroke-miterlimit: 10;
      }
      .st44 {
        fill: none;
        stroke: #333344;
        stroke-miterlimit: 10;
      }
      .st45 {
        fill: none;
        stroke: #f4efe8;
        stroke-miterlimit: 10;
      }
      .st46 {
        fill: #ffe690;
      }
      .st47 {
        fill: #5de7cd;
      }
      .st48 {
        fill: none;
        stroke: #333344;
        stroke-width: 1.056;
        stroke-miterlimit: 10;
      }
      .st49 {
        fill: none;
        stroke: #333344;
        stroke-width: 1.4143;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
      .st50 {
        fill: none;
        stroke: #333344;
        stroke-width: 1.4151;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st51 {
        fill: none;
        stroke: #333344;
        stroke-width: 1.055;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }
      .st52 {
        fill: none;
        stroke: #333344;
        stroke-width: 1.055;
        stroke-miterlimit: 10;
      }
      .st53 {
        fill: #abc4f9;
      }
    }

    .mood {
      transition: 0.5s ease;

      #face-angry,
      #face-sad,
      #face-happy {
        opacity: 0;
      }

      &.mood-1 {
        background: $coral-red;

        &.ethan {
          background: darken($coral-red, 10%);
        }
        .background {
          fill: darken($coral-red, 10%);
        }
        #face-angry {
          opacity: 1;
        }
        #face-neutral,
        #face-sad,
        #face-happy {
          opacity: 0;
        }
      }
      &.mood-2 {
        background: $blue-faves;
        .background {
          fill: darken($blue-faves, 10%);
        }
        #face-sad {
          opacity: 1;
        }
        #face-neutral,
        #face-angry,
        #face-happy {
          opacity: 0;
        }
      }
      &.mood-3 {
        background: $champagne-gold;
        .background {
          fill: darken($champagne-gold, 10%);
        }

        #face-happy {
          opacity: 1;
        }
        #face-neutral,
        #face-sad,
        #face-angry {
          opacity: 0;
        }
      }
    }

    .thought-bubble {
      opacity: 0;
      animation: fadeIn 0.5s ease 5s 1 normal forwards;
      background: url('/images/slides/activities/think-feel-care/thought-bubble.svg') no-repeat
        center / contain;
      width: 30vw;
      height: 20vw;
      display: block;
      position: absolute;
      margin-left: -13vw;
      left: 30vw;
      top: 3vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-breakpoint-up(xl) {
        left: 40vw;
        top: 10vh;
      }

      &.ethan {
        left: 30vw;
      }
      label {
        font-size: 18px;
        font-weight: 900;
        margin: 40px 20px 0 40px;
        width: 18vw;
        max-width: 400px;
        text-align: center;
      }
      .textarea {
        border: none;
        width: 100%;
        height: 7vw;
        background: transparent;
        text-align: center;
        font-size: 22px;
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;

        &.static {
          display: block;
          height: 9vw;
          width: 18vw;
          margin: 0 30px 0.5rem 30px;
          @extend h4;
          margin-top: 50px;

          @include media-breakpoint-up(xl) {
            margin-top: 100px;
          }
        }
      }
    }
    .bottom-question {
      position: absolute;
      width: 40vw;
      bottom: 5vh;
      left: 50vw;
      margin-left: -20vw;
      background: white;
      border-radius: 20px;
      box-shadow: $box-shadow;
      padding: 30px;
      text-align: center;
      .scale {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .range-slider {
        width: 100%;
      }
    }
    .mood,
    .option {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .count {
        font-size: 20px;
        font-weight: 700;
        padding-top: 20px;
      }
    }
    .btn.continue {
      padding: 5px 10px;
      margin: 10px auto 0;
      display: inline-block;
      font-size: 16px;
    }
    .middle-question {
      position: absolute;
      width: 60vw;
      top: 10vh;
      left: 50vw;
      margin-left: -30vw;
      background: white;
      border-radius: 20px;
      box-shadow: $box-shadow;
      padding: 30px;
      text-align: center;

      .btn {
        margin-top: 20px;
      }
      .care-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .option {
          width: 40%;
          margin: 10px;
          cursor: pointer;
          border-radius: 12px;
          border: 3px solid transparent;

          &.selected {
            //box-shadow: inset 0 0 2px 0 $blue-all-in;
            border-color: $blue-all-in;
          }

          &.teacher {
            @include media-breakpoint-up(xl) {
              width: 20%;
            }
            h5,
            .h5 {
              font-size: 14px;

              @include media-breakpoint-up(xl) {
                font-size: 20px;
              }
            }
          }

          &:nth-child(2) {
            margin-top: 10px;
          }
          img {
            width: 20vh;
          }
          h5,
          .h5 {
            margin-top: 10px;
          }
        }

        .image-button {
          background-color: transparent;
          color: $grey-space-cadet;
          padding: 0;
          line-height: 1.2;

          span.h5 {
            margin-bottom: 0.5rem;
          }
        }
      }

      &.no-images {
        .option {
          background: $blue-all-in;
          transition: $transition;
          color: white;
          &:hover {
            background: lighten($blue-all-in, 15%);
          }
          &.selected {
            background: darken($blue-all-in, 15%);
          }
          h5,
          .h5 {
            margin-top: 0;
          }
        }
      }
    }
    .care-badge {
      opacity: 0;
      animation: fadeIn 0.5s ease 1s 1 normal forwards;
      position: absolute;
      width: 20vw;
      height: 20vw;
      left: 50vw;
      margin-left: -10vw;
      bottom: 10vh;
      box-shadow: $box-shadow;
      border-radius: 50%;
      img {
        width: 100%;
        position: absolute;
        bottom: 0;

        &.img-1 {
          margin-left: -1.4vw;
          width: calc(100% + 1.4vw);
          max-width: unset;
          margin-top: -5.5vh;
        }
      }
    }
    .wait-teacher {
      position: absolute;
      bottom: 2vh;
      width: 25vw;
      text-align: center;
      margin-left: -12.5vw;
      opacity: 0;
      animation: fadeIn 0.5s ease 3s 1 normal forwards;
      left: 50vw;
      color: white;
      background: rgba(black, 30%);
      border-radius: 3px;
      padding: 5px 10px;
    }

    .bottom-left {
      bottom: 7vh;
      left: 5vw;
      margin-left: 0;
      width: 40vw;
    }
    .bottom-right {
      bottom: 7vh;
      right: 5vw;
      width: 40vw;
      left: auto;
      margin-left: 0;
      top: auto;
    }
  }

  &.teacher {
    .care-options {
      align-items: flex-end;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
