// Header
$menuItems: 5;
$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);

.header {
  .logo {
    max-width: 86px;
    max-height: 43px;
    transition: $transition;

    margin: 5px;
    position: absolute;
    top: 10px;
    left: 10px;

    @include pc-laptop-up {
      max-width: 86px;
      max-height: 63px;
      margin: 0 40px 0 0;
      position: static;
    }

    &:hover {
      opacity: $hover-opacity;
    }
  }
  .header-nav {
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left {
    display: flex;
    align-items: center;
  }
}

.burger-container {
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s $cubic;
  user-select: none;
  padding: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  z-index: 9999;
  &:hover {
    background-color: transparent;
  }
  .burger {
    width: 28px;
    height: 8px;
    position: relative;
    display: block;
    margin: auto;
    .bar {
      width: 100%;
      height: 2px;
      display: block;
      position: relative;
      background: $blue-all-in;
      transition: all 0.3s $cubic;
      transition-delay: 0s;
      &.topBar {
        transform: translateY(0px) rotate(0deg);
      }
      &.midBar {
        transform: translateY(6px) rotate(0deg);
      }
      &.btmBar {
        transform: translateY(12px) rotate(0deg);
      }
    }
  }
}

@include tablet-down {
  .burger-container {
    display: block;
  }
  .header .header-nav {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;

    overflow: hidden;
    transition: all 0.5s ease-out, background 1s ease-out;
    transition-delay: 0.2s;
    z-index: 11;

    .left,
    .right {
      width: 100%;
      padding: 0 30px;
    }

    ul.nav {
      position: relative;
      display: block;
      padding: 0px 0 0;
      list-style: none;
      width: 100%;
      li {
        border-bottom: 1px solid $grey-feedbacker;
        margin-top: 5px;
        transform: scale(1.15) translateY(-30px);
        opacity: 0;
        width: 100%;
        transition: transform 0.5s $cubic, opacity 0.6s $cubic;
        @for $i from 1 through $menuItems {
          &:nth-child(#{$i}) {
            transition-delay: #{0.56 - ($i * 0.07)}s;
          }
        }

        &.before-button-wrap {
          border-bottom: none;
        }

        &.button-wrap {
          border-bottom: none;
          margin-top: 30px;
          a {
            color: white;
            text-align: center;
          }
        }
        a, button {
          display: block;

          position: relative;
          color: $blue-all-in;
          // font-weight: 100;
          text-decoration: none;
          font-size: 22px;
          line-height: 2;
          // font-weight: 200;
          width: 100%;
          text-align: left
        }
      }
    }
  }
  .header.menu-opened {
    .header-nav {
      height: 100%;
      background-color: white;
      transition: all 0.3s ease-in, background 0.5s ease-in;
      transition-delay: 0.25s;
    }
    .burger-container {
      transform: translateY(5px) rotate(90deg);
      .burger {
        .bar {
          transition: all 0.4s $cubic;
          transition-delay: 0.2s;
          &.topBar {
            transform: translateY(6px) rotate(45deg);
          }
          &.btmBar {
            transform: translateY(3px) rotate(-45deg);
          }
          &.midBar {
            opacity: 0;
          }
        }
      }
    }

    .left ul.nav {
      li {
        transform: scale(1) translateY(0px);
        opacity: 1;
        @for $i from 1 through $menuItems {
          &:nth-child(#{$i}) {
            transition-delay: #{0.07 * $i + 0.2}s;
          }
        }
      }
    }
    .right ul.nav {
      li {
        transform: scale(1) translateY(0px);
        opacity: 1;
        @for $i from 1 through 2 {
          &:nth-child(#{$i}) {
            transition-delay: #{0.07 * ($i + 5) + 0.2}s;
          }
        }
      }
    }
    .icon {
      &.icon-bag {
        transform: translateX(75px);
        transition-delay: 0.3s;
      }
    }
  }
}
