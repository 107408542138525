// Animations

.a-delayed-pulse {
  animation-name: scalePulse;
  animation: scalePulse 1s ease 10s infinite alternate; //name duration timing-function delay iteration-count direction fill-mode;
  animation-delay: 6s;
}

@keyframes scalePulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
