// Slide here
.lesson-slide.definition-slide {
  &.theme-red {
    background-color: $coral-red !important;
  }
  &.layout-left {
    background-color: $grey-space-cadet;
    color: $white;
    .copy {
      left: 5%;
      bottom: 15%;
    }
    .animated-wrap {
      right: 0;
    }
    .a-scroll {
      right: 25%;
    }
  }
  &.layout-right {
    background-color: $blue-all-in;
    color: $white;
    .copy {
      top: 20%;
      right: 5%;
    }
    .animated-wrap {
      left: 0;
    }
    .a-scroll {
      left: -14%;
    }
  }

  .copy {
    @extend h3;
    margin: 0;
    font-weight: 900;
    color: $white;
    position: absolute;
    width: 60%;
    z-index: 10;
    @include pc-laptop-up {
      width: 40%;
    }
  }
  .animated-wrap {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  .skew {
    transform: rotate(-30deg);
  }
  .a-scroll {
    position: absolute;
    top: -200px;
    // transform: rotate(-30deg);
    animation: scroll 20s linear 0s infinite alternate;
    //animation: name duration timing-function delay iteration-count direction fill-mode;
    z-index: 1;
    opacity: 0.6;
  }
  span {
    min-width: 290px;
    min-height: 40px;
    display: block;
    color: white;
    margin: 5px;
    font-weight: 900;
    font-size: 60px;

    &:nth-child(5n + 1) {
      color: $green-say-yes;
    }
    &:nth-child(5n + 2) {
      color: $yellow;
    }
    &:nth-child(5n + 3) {
      color: $pink-you-and-me;
    }
    &:nth-child(5n + 4) {
      color: $blue-sky-crayola;
    }
    &:nth-child(5n + 5) {
      color: $white;
    }
  }
}
@keyframes scroll {
  100% {
    top: -800px;
    //transform: translate(-100px,-50px)
  } /* top is the number of spans (in this case 8) multiplied by span height (45px as described above)*/
}
