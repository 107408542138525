// Slide here
.lesson-slide.activity-stereotype-class-answers {
  background-color: $cream-seashell;
  color: $grey-space-cadet;

  text-align: center;

  .flex {
    width: 100%;
    justify-content: center;
    margin-top: 5vh;

    &.bottom-aligned {
      align-items: flex-end;
    }
  }

  h3, .h3 {
    font-weight: 900;
    margin: 20px 0 10px;
  }
  h4, .h4 {
    font-size: 24px;
  }

  img {
    height: 35vh;
    width: 100%;
    object-fit: cover;
    display: block;
  }

  .score {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $green-say-yes;
    color: white;
    text-align: center;
    font-weight: 900;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.red {
      background-color: $coral-red;
    }
  }
}
