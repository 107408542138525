// Side navigation
.side-nav {
  ul {
    margin: 0;
    padding: 0;
    position: sticky;
    top: 32px;
    max-width: 85%;
  }
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  > ul > li {
    margin-bottom: 16px;
    > a {
      @extend .h5;
      color: $blue-all-in;
    }
    > ul li {
      margin: 8px 0;
      line-height: 1.2;

      a {
        @extend .type-bsb;
        color: $grey-manatee;
      }
    }
  }
}
